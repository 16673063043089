import { makeAutoObservable } from 'mobx';

export interface PasswordPolicyResponse {
  enable_blacklist: boolean;
  id: number;
  max_age: number;
  max_length: number;
  min_complexity: number;
  min_length: number;
  name: string;
  reuse_count: number;
  system: boolean;
}

export class PasswordPolicy {
  enableBlacklist: boolean;
  id: number;
  maxAge: number;
  maxLength: number;
  minComplexity: number;
  minLength: number;
  name: string;
  reuseCount: number;
  system: boolean;

  constructor(
    enableBlacklist: boolean,
    id: number,
    maxAge: number,
    maxLength: number,
    minComplexity: number,
    minLength: number,
    name: string,
    reuseCount: number,
    system: boolean,
  ) {
    this.enableBlacklist = enableBlacklist;
    this.id = id;
    this.maxAge = maxAge;
    this.minComplexity = minComplexity;
    this.maxLength = maxLength;
    this.minLength = minLength;
    this.name = name;
    this.reuseCount = reuseCount;
    this.system = system;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  static fromRaw(obj: PasswordPolicyResponse | null): PasswordPolicy | null {
    if (!obj) return null;
    return new PasswordPolicy(
      obj.enable_blacklist,
      obj.id,
      obj.max_age,
      obj.max_length,
      obj.min_complexity,
      obj.min_length,
      obj.name,
      obj.reuse_count,
      obj.system,
    );
  }
}
