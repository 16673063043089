import { makeAutoObservable, runInAction } from 'mobx';

import { AuthProvider } from '../../model';
import AuthAPI from '../../services/api/auth';
import validator from '../../utils/validator';
import DataStoreContainer from '../data/data';

import { StoreTransactionState } from './types';

const USER_NOT_FOUND_ERROR_MESSAGE = "username isn't found";

class LoginScreenState {
  // loginState is the state of the current login transactions
  loginState: StoreTransactionState;

  // username is the string bound to the view input field containing the username
  username: string;

  errorMessage: string;

  constructor(private readonly dataStores: DataStoreContainer) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.loginState = 'idle';

    this.username = '';

    this.errorMessage = '';
  }

  setUsername(username: string) {
    this.username = username;
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  get isNextStepButtonEnabled(): boolean {
    // if the login state is in work return false early
    if (this.loginState === 'in-work') return false;

    return !validator.isEmptyString(this.username);
  }

  /**
   * performs login for known/unkown users
   */
  async performLoginIdentity(): Promise<string> {
    try {
      this.loginState = 'in-work';

      const { auth_url, allowed_idps } = await AuthAPI.postLoginIdentity(
        this.username,
      );

      if (!allowed_idps.length) {
        throw USER_NOT_FOUND_ERROR_MESSAGE;
      }

      this.setErrorMessage('');
      this.dataStores.userIdentityStore.setUsername(this.username);
      this.dataStores.userIdentityStore.fromRaw({
        auth_url,
        allowed_idps,
      });

      this.dataStores.userIdentityStore.isLocalUser &&
        this.dataStores.userIdentityStore.setPasswordProvider(
          AuthProvider.fromRaw(allowed_idps[0]),
        );

      return auth_url;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loginState = 'idle';
      });
    }
  }
}

export default LoginScreenState;
