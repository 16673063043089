import axios, { AxiosResponse } from 'axios';

const AgentBaseURL = 'http://localhost:61070';

const client = axios.create({
  baseURL: AgentBaseURL,
});

/**
 * logoutAgent attempts to logs out the user from the agent.
 */
export const logoutAgent = (): Promise<AxiosResponse> => {
  return client.post('/v1/sign_out');
};
