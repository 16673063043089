import axios, { AxiosResponse } from "axios";
import httpStatus from "http-status";
import { wrapWithEventBasedInterceptor } from "./interceptors";
import type { ISessionTimeoutEventPayload } from "./interface";
import { Event } from "./enum";

let client = axios.create({
  baseURL: "",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// check that were running in the browser
if (window !== undefined) {
  // define a new interceptor
  client = wrapWithEventBasedInterceptor(client);

  // redirect users based on event
  //@ts-ignore
  window.addEventListener(
    Event.SessionTimeout,
    (event: CustomEvent<ISessionTimeoutEventPayload>) => {
      window.location.assign(event.detail.location);
    }
  );
}

// onMissingSession allows outer user to listen to callbacks on Unauthorised location
// redirect and react accordingly
export const onMissingSession = (callback: (location: string) => void) => {
  client.interceptors.response.use(
    async (res: AxiosResponse): Promise<AxiosResponse> => {
      return res;
    },
    async (err) => {
      if (httpStatus.UNAUTHORIZED === err?.response?.status) {
        callback(err.response.headers["location"]);
        return;
      }

      throw err;
    }
  );
};

export default client;
