import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

import { observer } from 'mobx-react';

import {
  CyTheme,
  Divider,
  Text,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';

import { AuthProvider } from '../../../../model';
import { AppRoute } from '../../../../routes';
import { Idps } from '../../../../stores/data/user-identity-store';
import WelcomeUser from '../../Welcome/WelcomeForm/WelcomeUser';

import { IdpsList } from '.';

interface IdpsFormProps {
  username: string;
  idps: Idps | null;
  onProviderClick: (provider: AuthProvider) => void;
  onBack: () => void;
}

const IdpsForm: FC<IdpsFormProps> = ({
  username,
  idps,
  onProviderClick,
  onBack,
}) => {
  const theme = useThemeSafe();
  const styles = useStyles({ theme });

  return (
    <div>
      <div className={styles.container}>
        <img src="/public/login-icon.svg" alt="user" />
        <WelcomeUser username={username} />
      </div>
      <div className={styles.idpForm}>
        <div className={styles.sectionTitle}>
          <Text variant="table-body">Please select IDP to continue</Text>
        </div>
        {idps && (
          <div className={styles.idpsList}>
            <IdpsList idps={idps} onClick={onProviderClick} />
          </div>
        )}
        <Divider
          orientation="horizontal"
          thickness={1}
          className={styles.divider}
        />
        <div className={styles.loginWithAnotherUser}>
          <Link to={AppRoute.Login} onClick={onBack}>
            <Text variant="body" className={styles.linkText}>
              Login as a different user
            </Text>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default observer(IdpsForm);

const useStyles = createUseStyles((theme: CyTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& > *': {
      marginBottom: 8,
    },
  },
  idpForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'auto',
  },
  sectionTitle: {
    paddingBottom: 32,
  },
  idpsList: {
    flex: '0 0 auto',
    width: 200,
    marginBottom: 8,
  },
  divider: {
    marginTop: 48,
    marginBottom: 16,
    backgroundColor: theme.palette.background.color,
  },
  loginWithAnotherUser: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',

    '& a': {
      color: theme.palette.primary.color,
      textAlign: 'center',
      textDecoration: 'none',
    },
  },
  linkText: {
    color: theme.palette.primary.color,
  },
}));
