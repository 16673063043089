import { useContext } from 'react';

import { MobXProviderContext } from 'mobx-react';

import RootStore from '../stores/root-store';

/**
 * returns the mobx root store from react context
 * @returns
 */
export function useRootStore(): RootStore {
  return useContext(MobXProviderContext).rootStore as RootStore;
}
