const PortRange = {
  Min: 1,
  Max: 65535,
};

const RegexPatterns = {
  Dns: /^[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,

  Ip: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,

  IpV6: /^(?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4}$/,

  Url: /^https?:\/\/\w+(\.\w+)*(:[0-9]+)?(\/.*)?$/,

  ApplicationAddress: /^[a-z0-9-]*$/i,
  //TODO implement DN
  // activeDirectoryDN: /^(?:(?:CN|DC|OU|cn|dc|ou)=[a-zA-Z0-9_ ]+,?)*$/,

  Uppercase: /[A-Z]/,

  Lowercase: /[a-z]/,

  Symbols: /\W+/,

  Numbers: /[0-9]/,

  Webhook:
    /^(http(s?)):\/\/(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])((:[0-9]+)?)?(\/.*)?$/,

  DomainName:
    /^([a-zA-Z0-9_]{1}[a-zA-Z0-9_-]{0,62}){1}(\.[a-zA-Z0-9_]{1}[a-zA-Z0-9_-]{0,62})*[\._]?$/,

  DatabaseName: /^[a-z][a-z0-9_]{0,62}$/,
};

const MinPasswordLength = 8;
const MaxPasswordLength = 64;
const NeededComplexityChecksThreshold = 3;

// keys needed to show up in the decoded jwt
const NeededKeys = ['company', 'email', 'features', 'tenant', 'users'];

export default {
  PortRange,
  RegexPatterns,
  MinPasswordLength,
  MaxPasswordLength,
  NeededComplexityChecksThreshold,
  NeededKeys,
};
