import { CSSProperties } from 'react';
import { createUseStyles } from 'react-jss';

import { CyTheme } from '@frontend-monorepo/cyolo-ui';

const useStyles = createUseStyles<
  'root' | 'digit',
  {
    digitFontSize?: CSSProperties['fontSize'];
    large?: boolean;
  },
  CyTheme
>((theme: CyTheme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  digit: ({ digitFontSize, large }) => ({
    textAlign: 'center',
    width: 8,
    flex: 'auto',
    marginLeft: large ? 4 : 2,
    marginRight: large ? 4 : 2,
    minWidth: large ? 48 : undefined,
    minHeight: large ? 48 : undefined,
    outline: 'none',
    fontSize: digitFontSize ? digitFontSize : '1.2rem',
    caretColor: 'transparent',
    fontFamily: theme.typography.p.fontFamily,
    backgroundColor: 'transparent',
    border: large ? `1px solid ${theme.palette.secondary.color}` : 'none',
    borderRadius: large ? 4 : 0,
    WebkitBorderRadius: large ? 4 : 0,
    borderBottom: large
      ? undefined
      : `solid 2px ${theme.palette.grey.extraLight}`,
    color: theme.palette.secondary.color,

    ['&:focus']: {
      border: large ? `1.5px solid ${theme.palette.primary.color}` : undefined,
      borderBottom: large
        ? undefined
        : `solid 1.5px ${theme.palette.primary.color}`,
    },
  }),
}));

export default useStyles;
