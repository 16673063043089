import { action, makeObservable, observable } from 'mobx';

import { AppRoute } from '../routes';

class LocationStore {
  currentAppRoute: AppRoute;
  fullAppRoute?: AppRoute;

  constructor() {
    makeObservable(this, {
      currentAppRoute: observable,
      fullAppRoute: observable,
      updateLocation: action.bound,
    });

    this.currentAppRoute = AppRoute.Redirect;
  }

  // updateLocation is in charge of updating the current route
  // and validating new locations given to it
  updateLocation(newLocation: string): void {
    const allPaths = Object.values<string>(AppRoute);

    const validPathParts = newLocation
      .split('/')
      .filter((p) => allPaths.includes(p));

    if (!validPathParts.length) {
      console.warn('\nno path found for ', newLocation);
      return;
    }

    const lastPath = validPathParts[validPathParts.length - 1];

    this.currentAppRoute = lastPath as AppRoute;
  }
}

export default LocationStore;
