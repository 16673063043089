import React, { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import httpStatus from 'http-status';

import { observer } from 'mobx-react';

import { LoadingIndicator } from '@frontend-monorepo/cyolo-ui';

import { AppRoute } from '../../../../routes';
import { logoutAgent } from '../../../../services/agent/api';
import pageRedirect from '../../../../services/page-redirect';

import AgentLoggedOut from './AgentLoggedOut';

const AgentForceAgentLogout: FC = () => {
  const styles = useStyles();
  const [logoutRes, setLogoutRes] = useState<'loading' | 'success'>('loading');

  useEffect(() => {
    const agentLogout = async () => {
      try {
        const res = await logoutAgent();
        if (res.status === httpStatus.OK) {
          setLogoutRes('success');
        } else {
          pageRedirect.performPageRedirect(AppRoute.Login);
        }
      } catch (error) {
        pageRedirect.performPageRedirect(AppRoute.Login);
      }
    };

    agentLogout();
  }, []);

  return logoutRes === 'loading' ? (
    <div className={styles.spinnerContainer}>
      <LoadingIndicator color="blue" />
    </div>
  ) : (
    <AgentLoggedOut loggedFromBoth={true} />
  );
};

export default observer(AgentForceAgentLogout);

const useStyles = createUseStyles({
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
