export enum ApprovalState {
  pending = 'false',
  approved = 'approved',
  passPrepending = 'passPrepending',
  prePending = 'prePending',
  rejected = 'error',
  expired = 'expired',
  denied = 'denied',
}

export interface Approval {
  is_approved: boolean;
}

export interface ValidationError {
  failure_reason: string;
  remediation_link: string;
  retry_link: string;
  present_default_message: boolean;
}

export interface SupervisorApprovalTokenResponse {
  success: boolean;
  username: string;
  first_name: string;
  last_name: string;
  application_name: string;
}

export interface PendingApproval {
  approvalState: ApprovalState;
  expirationTime: string;
  approverReason: string;
  approverName: string;
}

export interface PendingApprovalResponse {
  approval_state: ApprovalState;
  expiration_time: string;
  approver_reason: string;
  approver_name: string;
}
