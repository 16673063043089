import { differenceInSeconds } from 'date-fns';
import ms from 'ms';
import pluralize from 'pluralize';

const ToOneBased = 1;
const FullDayLength = 2;
const FullMonthLength = 2;
export const timestampToDDMMYYYY = (timestamp: number): string => {
  const d = new Date(timestamp);
  let month = '' + (d.getMonth() + ToOneBased);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < FullMonthLength) month = '0' + month;
  if (day.length < FullDayLength) day = '0' + day;

  return [day, month, year].join('/');
};

const NoData = 'N/A';
export const ZeroValue = '0001-01-01T00:00:00Z';

export class FormatOptions {
  format: Intl.DateTimeFormatOptions = TIMESTAMP_FORMATTING_OPTIONS_MEDIUM;
  locale = 'en-US';
  zeroValueText = NoData;
}

export const TIMESTAMP_FORMATTING_OPTIONS_MEDIUM = {
  dateStyle: 'medium',
  timeStyle: 'medium',
} as Intl.DateTimeFormatOptions;
export const TIMESTAMP_FORMATTING_OPTIONS_LONG = {
  dateStyle: 'long',
} as Intl.DateTimeFormatOptions;

export const getDaysSince = (startTs: string): number => {
  const timeDelta = Math.max(Date.now() - new Date(startTs).getTime(), 0); // get delta with cutoff at zero
  return Math.round(timeDelta / ms('1d'));
};

export const getDaysBetween = (startTs: number, endTs: number): number => {
  const timeDelta = Math.max(endTs - startTs, 0); // get delta with cutoff at zero
  return Math.round(timeDelta / ms('1d'));
};

const ToMilliseconds = 1000;
export const secondsToMilis = (ts: number) => {
  return ts * ToMilliseconds;
};

export const formatTimestampToDate = (
  timestamp: string,
  formatOptions: FormatOptions = new FormatOptions(),
) => {
  if (!timestamp || timestamp?.length === 0 || timestamp == ZeroValue) {
    return formatOptions.zeroValueText;
  }
  return new Date(timestamp).toLocaleString(
    formatOptions.locale,
    formatOptions.format,
  );
};

interface RemainingTime {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const HOUR_IN_SECONDS = 3600;
const MINUTE_IN_SECOND = 60;
const HOUR_IN_DAY = 24;

export const calculateTimeRemaining = (targetDate: Date): RemainingTime => {
  const now = new Date();
  const difference = differenceInSeconds(targetDate, now);

  if (difference <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }
  const days = Math.floor(difference / (HOUR_IN_SECONDS * HOUR_IN_DAY));

  const hours = Math.floor(
    (difference % (HOUR_IN_SECONDS * HOUR_IN_DAY)) / HOUR_IN_SECONDS,
  );
  const minutes = Math.floor((difference % HOUR_IN_SECONDS) / MINUTE_IN_SECOND);
  const seconds = difference % MINUTE_IN_SECOND;
  return { days, hours, minutes, seconds };
};

const MAX_NUMBER_FOR_LEADING_ZERO = 9;

const withLeadingZero = (num: number): string => {
  if (num !== 0 && !Boolean(num)) {
    return '';
  }
  if (num > MAX_NUMBER_FOR_LEADING_ZERO) {
    return num.toString();
  }
  return '0' + num;
};

export const calculateTimeRemainingString = (targetDate: Date): string => {
  const { days, hours, minutes, seconds } = calculateTimeRemaining(targetDate);

  if (!days && !hours && !minutes && !seconds) {
    return '';
  }

  const dayString = days > 0 ? `${days} ${pluralize('day', days)} ` : '';

  return `${dayString}${withLeadingZero(hours)}:${withLeadingZero(
    minutes,
  )}:${withLeadingZero(seconds)}`;
};

const isValidDate = (date: Date | string | null | undefined): boolean => {
  return date instanceof Date && !isNaN(date.getTime());
};

const nextToOrder = 1;

export const sortDate = (
  a: Date | string | null | undefined,
  b: Date | string | null | undefined,
): number => {
  if (!a && !b) {
    return -1;
  }

  if (!a) {
    return -1;
  }

  if (!b) {
    return nextToOrder;
  }

  const dateA = typeof a === 'string' ? new Date(a) : a;
  const dateB = typeof b === 'string' ? new Date(b) : b;

  if (!isValidDate(dateA) || !isValidDate(dateB)) {
    return 0;
  }

  return dateA.getTime() - dateB.getTime();
};

export default {
  formatTimestampToDate,
  getDaysBetween,
  getDaysSince,
  secondsToMilis,
  timestampToDDMMYYYY,
  ZeroValue,
  calculateTimeRemaining,
  sortDate,
};
