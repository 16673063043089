import { action, makeObservable, observable } from 'mobx';

import passwordApi, { ValidateType } from '../../../services/password-api';

export class PasswordPolicyValidState {
  isPending = false;
  isHistoryValid = false;
  isBlacklistValid = false;

  constructor() {
    makeObservable(this, {
      isPending: observable,
      isHistoryValid: observable,
      isBlacklistValid: observable,
      validatePassword: action.bound,
    });
  }

  async validatePassword(
    password: string,
    type: ValidateType,
    onError?: (err: unknown) => void,
  ): Promise<void> {
    if (this.isPending) {
      return;
    }

    try {
      this.isPending = true;

      const validationList = await passwordApi.validatePassword(password, type);

      const pastUsed = validationList.find(
        (item) => item.code === 'nonPastUsed',
      );
      const blackList = validationList.find(
        (item) => item.code === 'nonBlackListed',
      );

      this.isHistoryValid = pastUsed?.is_valid || false;
      this.isBlacklistValid = blackList?.is_valid || false;
    } catch (err) {
      this.isBlacklistValid = false;
      this.isHistoryValid = false;
      onError?.(err);
    } finally {
      this.isPending = false;
    }
  }
}
