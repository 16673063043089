import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import { observer } from 'mobx-react';

import {
  Button,
  CyTheme,
  Image,
  Text,
  TextField,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';

import LoginIcon from '../../../../../public/login-icon.svg';
import { useKeyboardPress } from '../../../hooks';

interface LoginFormProps {
  username: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onNextStepClick: () => void;
  isNextStepActive: boolean;
  errorMessage: string;
}

const ENTER_KEY_CODE = 13;

const LoginForm: FC<LoginFormProps> = ({
  username,
  onChange,
  onNextStepClick,
  isNextStepActive,
  errorMessage,
}) => {
  const theme = useThemeSafe();
  const styles = useStyles({ theme });

  useKeyboardPress(ENTER_KEY_CODE, onNextStepClick);

  return (
    <div>
      <div className={styles.loginWelcomeContainer}>
        <Image src={LoginIcon} alt="user" />
        <Text variant="login-sub-title">Welcome</Text>
      </div>
      <div className={styles.userForm}>
        <div className={styles.sectionTitle}>
          <Text variant="table-body">Please enter your username</Text>
        </div>
        <div className={styles.inputFieldWrapper}>
          <div className={styles.inputField}>
            <TextField
              id="username-field"
              type="text"
              value={username}
              placeholder="Username"
              onChange={onChange}
              autoFocus={true}
            />
          </div>
          {errorMessage && (
            <Text variant="tag" className={styles.errorMsg}>
              {errorMessage}
            </Text>
          )}
        </div>
        <Button
          id="login-button"
          variant="primary"
          className={styles.nextStepSubmitBtn}
          onClick={onNextStepClick}
          disabled={!isNextStepActive}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default observer(LoginForm);

const useStyles = createUseStyles((theme: CyTheme) => ({
  loginWelcomeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& > *': {
      marginBottom: 8,
    },
  },
  userForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionTitle: {
    paddingBottom: 32,
  },
  inputFieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputField: {
    flex: '0 0 auto',
    width: 200,
    marginBottom: 8,
  },
  nextStepSubmitBtn: {
    width: 200,
  },
  errorMsg: {
    transition: theme.transitions.fast,
    margin: '0px -8px 8px 0px',
    color: `${theme.palette.error.color} !important`,
    display: 'flex',
    width: 0,
    minWidth: '100%',
  },
}));
