import { action, makeObservable, observable, reaction } from 'mobx';

import { ValidateType } from '../services/password-api';
import { PasswordPolicyValidState } from '../stores/state-store/update-and-reset-password-screen-state';

export type PasswordValidationType = 'regex' | 'number';

export type PasswordValidationCode =
  | 'number'
  | 'uppercase'
  | 'lowercase'
  | 'maxLength'
  | 'minLength'
  | 'specialCharacter'
  | 'numberCharacter';

export type PasswordRuleDescription = {
  code: PasswordValidationCode;
  type: PasswordValidationType;
  value: string; //could be a regex string - '/ ... /g' or string length value  - '8'
};

const staticRulePromise = async (cb: () => void, timeout = 300) => {
  await new Promise((resolve) => {
    setTimeout(() => {
      cb();
      resolve('success');
    }, timeout);
  });
};

abstract class PasswordRuleBase {
  isPending = false;
  isValid = false;

  constructor() {
    makeObservable(
      this,
      {
        validatePassword: action.bound,
        isPending: observable,
        isValid: observable,
      },
      { autoBind: true },
    );
  }

  abstract validatePassword(
    password: string,
    type?: ValidateType,
    onError?: (err: unknown) => void,
  ): Promise<void>;

  reset() {
    this.isPending = false;
    this.isValid = false;
  }
}

export class PasswordRuleSetRule extends PasswordRuleBase {
  code: PasswordValidationCode;
  type: PasswordValidationType;
  value: string;

  constructor(rule: PasswordRuleDescription) {
    super();
    this.code = rule.code;
    this.type = rule.type;
    this.value = rule.value;
  }

  private passwordRegexCheck(password: string, regExp: RegExp): boolean {
    return regExp.test(password);
  }

  /**
   * checks the new password against by length rule
   * @param password
   */
  private passwordLengthCheck(password: string): boolean {
    const valueToNumber = Number(this.value);

    if (password.length) {
      if (this.code === 'maxLength') {
        return password.length <= valueToNumber;
      }

      if (this.code === 'minLength') {
        return password.length >= valueToNumber;
      }
    }

    return false;
  }

  /**
   * checks the new password against a given rule
   * @param password
   */
  async validatePassword(password: string): Promise<void> {
    // don't show spinner on validated rule
    if (!this.isValid) {
      this.isPending = true;
    }

    await staticRulePromise(() => {
      switch (this.type) {
        case 'regex':
          this.isValid = this.passwordRegexCheck(
            password,
            new RegExp(this.value),
          );
          break;
        case 'number':
          this.isValid = this.passwordLengthCheck(password);
          break;
        default:
          this.isValid = false;
          break;
      }
      this.isPending = false;
    });
  }
}

export class PasswordBlacklistRule extends PasswordRuleBase {
  state: PasswordPolicyValidState;
  constructor(state: PasswordPolicyValidState) {
    super();
    this.state = state;

    makeObservable(this, { state: observable });

    reaction(
      (): [boolean, boolean] => [
        this.state.isPending,
        this.state.isBlacklistValid,
      ],
      ([isPending, isValid]) => {
        this.isPending = isPending;
        this.isValid = isValid;
      },
    );
  }

  async validatePassword(
    password: string,
    type: ValidateType,
    onError: (err: unknown) => void,
  ): Promise<void> {
    await this.state.validatePassword(password, type, onError);
  }
}

export class PasswordHistoryRule extends PasswordRuleBase {
  state: PasswordPolicyValidState;
  constructor(state: PasswordPolicyValidState) {
    super();
    this.state = state;

    makeObservable(this, { state: observable });

    reaction(
      (): [boolean, boolean] => [
        this.state.isPending,
        this.state.isHistoryValid,
      ],
      ([isPending, isValid]) => {
        this.isPending = isPending;
        this.isValid = isValid;
      },
    );
  }

  async validatePassword(
    password: string,
    type: ValidateType,
    onError: (err: unknown) => void,
  ): Promise<void> {
    await this.state.validatePassword(password, type, onError);
  }
}

export class PasswordRuleLength extends PasswordRuleBase {
  constructor(
    public lengthNum: number,
    private type: 'minLength' | 'maxLength',
  ) {
    super();
  }

  async validatePassword(password: string): Promise<void> {
    // don't show spinner on validated rule
    if (!this.isValid) {
      this.isPending = true;
    }

    await staticRulePromise(() => {
      switch (this.type) {
        case 'maxLength':
          this.isValid = password.length <= this.lengthNum;
          break;
        case 'minLength':
          this.isValid = password.length >= this.lengthNum;
          break;
        default:
          this.isPending = false;
      }
      this.isPending = false;
    });
  }
}
