import React, { Component, FC } from 'react';

import { IReactionDisposer, reaction } from 'mobx';
import { observer } from 'mobx-react';

import { hasToastSupport } from '../../interface';
import Toast from '../../models/toast';
import { ContextProps, ToastContext } from '../ToastContext/index';

interface MobxToastAdapterProps {
  store: hasToastSupport;
}

interface MobxToastLogicProps {
  store: hasToastSupport;
  toastCtx: ContextProps;
}

// the logic part which gets the context data and allows you to
// integrate mobx with cyolo toast
class MobxToastLogic extends Component<MobxToastLogicProps, unknown> {
  disposable?: IReactionDisposer;
  componentDidMount() {
    const { store, toastCtx } = this.props;
    this.disposable = reaction(
      // must do store.active toast or mobx observable doesnt work, cant destructure further
      () => store.activeToast,
      (toast: Toast) => {
        if (!toast) return;
        toastCtx.showToast(toast.message, toast.type, {
          timeout: toast.timeout,
        });
      },
    );
  }

  componentWillUnmount() {
    this.disposable && this.disposable();
  }

  render() {
    return null;
  }
}

// gives the logic comp context from ToastContext
const MobXToastAdapter: FC<MobxToastAdapterProps> = ({ store }) => (
  <ToastContext.Consumer>
    {(toastCtx) => <MobxToastLogic store={store} toastCtx={toastCtx} />}
  </ToastContext.Consumer>
);

export default observer(MobXToastAdapter);
