import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

import { observer } from 'mobx-react';

import {
  Button,
  Card,
  CyTheme,
  Divider,
  Text,
  TextField,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';

import { useRootStore } from '../../../hooks/useRootStore';
import { AppRoute } from '../../../routes';

const useStyles = createUseStyles((theme: CyTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    gap: 30,
    width: 350,
    padding: '40 50',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    textAlign: 'center',
    alignSelf: 'center',
    width: 350,
  },
  input: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    width: 242,
    gap: 8,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row-reverse',

    width: '100%',
    marginTop: '20',

    '& a': {
      color: theme.palette.primary.color,
      fontFamily: 'sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      textAlign: 'center',
      textDecoration: 'none',
    },
  },
  cancelButton: {
    border: 'none',
    padding: 0,
    outline: 'none',
    background: 'transparent',
    marginLeft: 'auto',
    cursor: 'pointer',
  },
}));

const UsernameStep: FC = () => {
  const {
    stateStore: { requestPasswordResetState: store },
    uiStore,
  } = useRootStore();

  const theme = useThemeSafe();
  const styles = useStyles({ theme });

  const submit = async () => {
    try {
      uiStore.setLoadingState('pending');
      await store.submitUserResetRequest();
      uiStore.setLoadingState('done');
    } catch (e) {
      uiStore.setLoadingState('error');
    }
  };

  return (
    <Card className={styles.root}>
      <div className={styles.header}>
        <Text variant="login-sub-title">Forgot Password</Text>
        <Text variant="body">Please enter your username to continue*</Text>
      </div>
      <div className={styles.input}>
        <TextField
          value={store.username}
          placeholder="Username"
          onChange={(e) => store.setUsername(e.target.value)}
        />
        <Button
          variant="primary"
          disabled={!store.usernameValid}
          onClick={submit}
        >
          Next
        </Button>
      </div>
      <div className={styles.header}>
        <Divider orientation="horizontal" thickness={1} />
        <Text variant="body" color={theme.palette.grey.color}>
          *You can reset your password if your administrator has configured your
          settings with either a mobile phone number or an email address.
        </Text>
      </div>
      <div className={styles.footer}>
        <Link to={AppRoute.Password}>Cancel</Link>
      </div>
    </Card>
  );
};

export default observer(UsernameStep);
