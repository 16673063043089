const PhasesSkippedKey = 'skipped-phases';

export type EnrollmentPhase = 'rdp';

const get = (): Array<EnrollmentPhase> => {
  /**
   * get returns an enrollment phase array of skipped phases from session storage
   */

  // get skipped phases as raw string from session storage, default to empty string
  const rawSkippedPhases = sessionStorage.getItem(PhasesSkippedKey) || '';

  // split up the phases using , and cast them to the EnrollmentPhase type
  return rawSkippedPhases
    .split(',')
    .map((rawPhase) => rawPhase as EnrollmentPhase);
};

const set = (phaseIdArray: Array<EnrollmentPhase>): void => {
  /**
   * set sets the skipped phases array in session storage
   */
  sessionStorage.setItem(PhasesSkippedKey, phaseIdArray.join(','));
};

const add = (phaseId: EnrollmentPhase): void => {
  /**
   * add adds one phase to the skipped phases array in session storage
   */
  set([...get(), phaseId]);
};

const remove = (phaseId: EnrollmentPhase): void => {
  /**
   * remove removes one phase from the skipped phases array in session storage
   */
  const currentPhases = get();
  const newFilteredPhases: Array<EnrollmentPhase> = currentPhases.filter(
    (id) => id !== phaseId,
  );
  set(newFilteredPhases);
};

export default {
  add,
  remove,
  get,
  set,
};
