import { makeAutoObservable, reaction, runInAction } from 'mobx';

import pageRedirect from '../../services/page-redirect';
import { ResponseError } from '../../services/response';
import validator from '../../utils/validator';
import DataStoreContainer from '../data/data';

import { StoreTransactionState } from './types';

enum RawErrorMessage {
  InvalidUsernameOrPassword = 'username or password incorrect',
  ContactWithAdmin = 'an error occured, please contact your admin',
}

enum ErrorMessage {
  InvalidUsernameOrPassword = 'Invalid username or password ',
}

const isBackendLoginError = (obj: unknown): obj is ResponseError => {
  return (
    (obj as ResponseError)?.response?.data?.error ===
      RawErrorMessage.InvalidUsernameOrPassword ||
    (obj as ResponseError)?.response?.data?.error ===
      RawErrorMessage.ContactWithAdmin
  );
};

export const parseBackendLoginError = (error: unknown): string => {
  if (isBackendLoginError(error)) {
    return ErrorMessage.InvalidUsernameOrPassword;
  }

  return '';
};

class SetPasswordScreenState {
  passwordState?: StoreTransactionState;

  password: string;

  errorMessage: string;

  constructor(private readonly dataStores: DataStoreContainer) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.password = '';

    this.errorMessage = '';

    reaction(
      () => this.dataStores.userIdentityStore.data.passwordProvider,
      (passwordProvider) => {
        if (!Boolean(passwordProvider)) {
          this.passwordState = 'in-work';

          return;
        }

        this.passwordState = 'idle';
      },
    );
  }

  setPassword(password: string) {
    this.password = password;
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  get isLoginButtonEnabled(): boolean {
    // if the login state is in work return false early
    if (this.passwordState === 'in-work') return false;

    return !validator.isEmptyString(this.password);
  }

  /**
   * perform a login against the IDAC (First Party)
   * @returns
   */
  async performFirstPartyLogin(): Promise<void> {
    // change set password state
    this.passwordState = 'in-work';

    try {
      // perform dynamic login

      const { shouldPerformRedirection } =
        await this.dataStores.userIdentityStore.authenticateUser(this.password);

      this.setErrorMessage('');

      // if the result defines that we need to redirect,
      // then perform a redirection to the disabled screen
      if (shouldPerformRedirection) {
        pageRedirect.performPageRedirect(`${window.origin}/disabled`);
        return;
      }

      pageRedirect.performPageRedirect();
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.passwordState = 'idle';
      });
    }
  }

  async performBackAction(): Promise<void> {
    await this.dataStores.userIdentityStore.clearSelectedUserIdentity();
  }
}

export default SetPasswordScreenState;
