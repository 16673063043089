import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

import {
  CyTheme,
  PasswordField,
  Text,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';

interface PasswordAndConfirmProps {
  newPasswordValue: string;
  confirmPasswordValue: string;
  showError: boolean;
  errorMessage: string;
  handleNewPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleConfirmPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PasswordAndConfirm: FC<
  React.PropsWithChildren<PasswordAndConfirmProps>
> = ({
  newPasswordValue,
  confirmPasswordValue,
  errorMessage,
  handleConfirmPasswordChange,
  handleNewPasswordChange,
  showError,
}) => {
  const theme = useThemeSafe();
  const styles = useStyles({ theme });

  return (
    <>
      <PasswordField
        placeholder="New password"
        value={newPasswordValue}
        className={clsx({ [styles.inputError]: showError })}
        onChange={handleNewPasswordChange}
      />
      <div className={styles.confirmWrapper}>
        <PasswordField
          placeholder="Confirm new password"
          value={confirmPasswordValue}
          onChange={handleConfirmPasswordChange}
          className={clsx({ [styles.inputError]: showError })}
        />
        {showError && (
          <Text variant="tag" className={styles.errorMsg}>
            {errorMessage}
          </Text>
        )}
      </div>
    </>
  );
};

export default PasswordAndConfirm;

const useStyles = createUseStyles((theme: CyTheme) => ({
  inputError: {
    borderColor: theme.palette.error.color,

    '& > input': {
      color: theme.palette.error.color,
    },
  },
  confirmWrapper: {
    position: 'relative',
    marginBottom: 4,
  },
  errorMsg: {
    maxWidth: 200,
    color: theme.palette.error.color,
  },
}));
