import { createContext } from 'react';

import { ToastOptions } from '../../interface';
import Toast, { ToastType } from '../../models/toast';
// TODO naming
export interface ContextProps {
  toasts: Toast[];
  showToast: (
    message: string,
    type?: ToastType,
    options?: ToastOptions,
  ) => void;
  deleteToast: (id: string) => void;
}

const initialState = null;

export const ToastContext = createContext<ContextProps>(initialState);

export const { Consumer, Provider } = ToastContext;
