import { autorun } from 'mobx';

import { AppRoute } from '../routes';
import { ApprovalState } from '../services/api/response';
import pageRedirect from '../services/page-redirect';
import DataStoreContainer from '../stores/data/data';
import LocationStore from '../stores/location-store';
import { HoldupType } from '../stores/state-store/waiting-screen-state';

const HoldupScreens = [AppRoute.Supervisor, AppRoute.ApprovalWaiting];

// TODO: consider changing this

// HoldupRedirector is in charge of redirecting the user
// back to the IDAC when he is in the waiting screen
// waiting for different types of holdups to clear up
class HoldupRedirector {
  constructor(
    locationStore: LocationStore,
    private dataStores: DataStoreContainer,
  ) {
    autorun(() => {
      const { currentAppRoute } = locationStore;

      if (!HoldupScreens.includes(currentAppRoute)) return;

      // check if were in the supervisor holdup
      if (currentAppRoute === AppRoute.Supervisor) {
        // if the user did not pass mfa yet, early return

        if (!dataStores.mfaDataStore.data) return;
      }

      // check if were in the supervisor approval waiting holdup
      if (currentAppRoute === AppRoute.ApprovalWaiting) {
        // if the session approval not approved yet, hold the user and try polling again
        switch (dataStores.approvalStore.state) {
          case ApprovalState.approved:
            // perform special redirect for approvals with id
            pageRedirect.performApprovalApprovedPageRedirect();
            return;
          case ApprovalState.expired:
            pageRedirect.performApprovalPageRedirect(HoldupType.Expired);
            return;
          case ApprovalState.denied:
            pageRedirect.performApprovalPageRedirect(HoldupType.Denied);
            return;
          case ApprovalState.rejected:
            pageRedirect.performPageRedirectUnauthorized();
            return;
          default:
            return;
        }
      }

      // if all holdups were cleared, redirect the user
      pageRedirect.performPageRedirect();
    });
  }
}

export default HoldupRedirector;
