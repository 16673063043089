import { action, computed, makeObservable, observable } from 'mobx';

import { PasswordValidator, PolicyRulesValidator } from '../../../model';

import NewPasswordState from './new-password-state';

abstract class ResetPasswordScreenBase {
  validator: PasswordValidator | null = null;
  policyValidator: PolicyRulesValidator | null = null;
  newPasswordState: NewPasswordState;

  // IDP rules initial values set to false
  enableBlackList = false;

  constructor() {
    makeObservable(this, {
      newPasswordState: observable,
      isValid: computed,
      isComplexityValid: computed,
      doPasswordsMatch: computed,
      setupValidator: action.bound,
      resetPassword: action.bound,
      policyValidator: observable,
      validator: observable,
      enableBlackList: observable,
    });

    this.newPasswordState = new NewPasswordState();
  }

  abstract get isComplexityValid(): boolean;

  abstract get doPasswordsMatch(): boolean;

  abstract get isValid(): boolean;

  abstract setupValidator(policyOptions?: {
    minComplexity: number;
    minLength: number;
    maxLength: number;
    enableBlacklist: boolean;
  }): Promise<void>;

  abstract resetPassword(): Promise<void>;
}

export default ResetPasswordScreenBase;
