import { Store } from '@frontend-monorepo/cyolo-store';

import { User } from '../../model';
import { UserAPI } from '../../services/api';

import { ICurrentUserStore } from './interface';

export class CurrentUserStore extends Store<User> implements ICurrentUserStore {
  constructor() {
    super(
      'current_user',
      async () => {
        return User.fromRaw(await UserAPI.getUserData());
      },
      User.EmptyUser(),
    );
  }
}
