const Endpoints = {
  methods: '/v1/users/me/mfa/methods',
  sendOtp: '/v1/users/me/otp/send',
  userProvider: '/v1/users/me/provider',
  codeValidation: '/v1/users/me/otp/verify',
  validation: '/v1/users/me/validation',
  logout: '/cyolo/v1/logout',
  legalDoc: '/v1/users/me/legal-documentation',
  approveRequest: '/v1/auth/stage/2',
  userAwaitApproval: '/v1/users/await_approval',
};

export default Endpoints;
