import { makeAutoObservable, reaction, runInAction } from 'mobx';

import { AuthProvider } from '../../model';
import pageRedirect from '../../services/page-redirect';
import DataStoreContainer from '../data/data';

import { StoreTransactionState } from './types';

class IdpScreenState {
  idpState?: StoreTransactionState;

  constructor(private readonly dataStores: DataStoreContainer) {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.dataStores.userIdentityStore.externalUserProviders,
      (externalUserProviders) => {
        if (externalUserProviders === null) {
          this.idpState = 'in-work';

          return;
        }

        this.idpState = 'idle';
      },
    );
  }

  async selectProvider(provider: AuthProvider): Promise<void> {
    this.idpState = 'in-work';

    try {
      const { url, submitForm } = provider;

      if (submitForm) {
        this.dataStores.userIdentityStore.setPasswordProvider(provider);

        return;
      }

      pageRedirect.performPageRedirect(url);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => {
        this.idpState = 'idle';
      });
    }
  }

  async performBackAction(): Promise<void> {
    await this.dataStores.userIdentityStore.clearSelectedUserIdentity();
  }
}

export default IdpScreenState;
