const ByteUnitsFactor = 1024;
const FileSizeMinValueBeforeByteUnits = 0.1;
const FileSizePrecision = 1;

/**
 * formatFileSize get bytes as number and returns string
 * with user friendly units.
 * @param {number} val - the value to be formatted.
 */
const formatFileSize = (val: number): string => {
  if (val <= 0) return '-';
  let i = -1;
  const byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    val = val / ByteUnitsFactor;
    i++;
  } while (val > ByteUnitsFactor);

  return (
    Math.max(val, FileSizeMinValueBeforeByteUnits).toFixed(FileSizePrecision) +
    byteUnits[i]
  );
};

const NumberOfSecondsInHour = 3600;
const NumberOfSecondsInMinute = 60;

// formatDurationFromSeconds get number of seconds and
// returns time formatted string as hh:mm:ss.
const formatDurationFromSeconds = (val: number): string => {
  if (val <= 0) return '-';
  const hours = Math.floor(val / NumberOfSecondsInHour);
  const minutes = Math.floor(
    (val - hours * NumberOfSecondsInHour) / NumberOfSecondsInMinute,
  );
  const seconds =
    val - hours * NumberOfSecondsInHour - minutes * NumberOfSecondsInMinute;

  return `${formatUnitOfTime(hours)}:${formatUnitOfTime(
    minutes,
  )}:${formatUnitOfTime(seconds)}`;
};

const FormattingLength = 2;
const formatUnitOfTime = (value: number): string => {
  return `${value
    .toString()
    .padStart(FormattingLength, '0')
    .slice(-FormattingLength)}`;
};

export { formatDurationFromSeconds, formatFileSize };
