import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const validatePhoneNumber = (phoneNumber: string): boolean => {
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
  if (!parsedPhoneNumber) return false;
  return parsedPhoneNumber.isValid();
};

const isEmptyString = (string: string): boolean =>
  !Boolean(string) && string.replace(' ', '') === '';

export default {
  validatePhoneNumber,
  isEmptyString,
};
