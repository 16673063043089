interface URLData {
  host: string;
  protocol: string;
}

export enum AppPath {
  USERS = 'users',
  RECORDINGS = 'recordings',
  LOGIN = 'login',
  AGENT = 'agents',
  ForwardProxy = 'forward-proxy',
  CONSOLE = 'console',
  SUPERVISOR = 'supervisor',
  IDAC_CONNECTION = 'system-ssh',
  FILE_TRANSFER = 'file-transfer',
}

const LastDotLength = 1;
export const getAppHost = (appUrlName: AppPath) => {
  const { host, protocol }: URLData = window.location;
  const newHost = [appUrlName, ...host.split('.').slice(LastDotLength)].join(
    '.',
  );
  return `${protocol}//${newHost}`;
};

export const getAppHostURLWithSite = (site: string, app: AppPath): string => {
  const url = new URL(getAppHost(app));
  url.hostname = `_${site}_${url.hostname}`;
  return url.toString();
};
