import { makeAutoObservable } from 'mobx';

import api from '../../services/api/api';
import { SupervisorApprovalTokenResponse } from '../../services/api/response';

class ApproverStateStore {
  approval?: SupervisorApprovalTokenResponse;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  approve = async (code: string, policyId: string): Promise<void> => {
    this.approval = await api.approveRequest(code, policyId);
  };
}

export default ApproverStateStore;
