import { CurrentUserStore } from '@frontend-monorepo/cyolo-identity';

import DataStoreContainer from '../data/data';
import LocationStore from '../location-store';
import UiStore from '../ui-store';

import RequestPasswordResetState from './update-and-reset-password-screen-state/request-password-reset-state';
import ResetInvitedUserPasswordScreenState from './update-and-reset-password-screen-state/reset-invited-user-password-screen-state';
import ApproverStateStore from './approver-state-store';
import EnrollScreenState from './enroll-screen-state';
import IdpScreenState from './idp-screen-state';
import LoginScreenState from './login-screen-state';
import MfaScreenState from './mfa-screen-state';
import PasswordScreenState from './password-screen-state';
import RedirectScreenState from './redirect-screen-state';
import {
  ResetPasswordScreenState,
  UpdatePasswordScreenState,
} from './update-and-reset-password-screen-state';
import WaitingScreenState from './waiting-screen-state';

class StateStore {
  readonly enrollScreenState: EnrollScreenState;

  readonly loginScreenState: LoginScreenState;

  readonly mfaScreenState: MfaScreenState;

  readonly waitingScreenState: WaitingScreenState;

  readonly redirectScreenState: RedirectScreenState;

  readonly updatePasswordScreenState: UpdatePasswordScreenState;

  readonly resetPasswordScreenState: ResetPasswordScreenState;

  readonly requestPasswordResetState: RequestPasswordResetState;

  readonly idpScreenState: IdpScreenState;

  readonly passwordScreenState: PasswordScreenState;

  readonly invitedUserScreenState: ResetInvitedUserPasswordScreenState;

  readonly userStore: CurrentUserStore;

  readonly approvalStateStore: ApproverStateStore;

  constructor(
    dataStores: DataStoreContainer,
    locationStore: LocationStore,
    uiStore: UiStore,
  ) {
    this.enrollScreenState = new EnrollScreenState(dataStores, uiStore);
    this.mfaScreenState = new MfaScreenState(dataStores, locationStore);
    this.loginScreenState = new LoginScreenState(dataStores);
    this.passwordScreenState = new PasswordScreenState(dataStores);
    this.waitingScreenState = new WaitingScreenState(dataStores, locationStore);
    this.redirectScreenState = new RedirectScreenState(dataStores);
    this.idpScreenState = new IdpScreenState(dataStores);
    this.requestPasswordResetState = new RequestPasswordResetState(dataStores);
    this.updatePasswordScreenState = new UpdatePasswordScreenState();
    this.resetPasswordScreenState = new ResetPasswordScreenState();
    this.invitedUserScreenState = new ResetInvitedUserPasswordScreenState();
    this.userStore = new CurrentUserStore();
    this.approvalStateStore = new ApproverStateStore();
  }
}

export default StateStore;
