import { makeObservable, observable } from 'mobx';

export enum EnrollmentRequirementId {
  DesktopAddress = 'desktop_policy', // needs to enter remote desktop address
  Mfa = 'mfa',
  AcceptLegalDocumentation = 'accept_legal_documentation',
}

class EnrollmentRequirement {
  id: EnrollmentRequirementId;

  constructor(id: string) {
    makeObservable(this, {
      id: observable,
    });

    this.id = id as EnrollmentRequirementId;
  }
}

export default EnrollmentRequirement;
