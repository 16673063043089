//@ts-nocheck

// Opera 8.0+
export const isOpera =
  (!!window.opr && !!opr.addons) ||
  !!window.opera ||
  navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementexport constructor]"
export const isSafari = () => {
  const isSafariUserAgent = /^((?!chrome|android).)*safari/i.test(
    navigator.userAgent,
  );
  // chrom IOS matched with safari userAgent
  const isChromeIOS =
    /CriOS/i.test(navigator.userAgent) &&
    /iphone|ipod|ipad/i.test(navigator.userAgent);
  return isSafariUserAgent && !isChromeIOS;
};

// Internet Explorer 6-11
export const isIE = /*@cc_on!@*/ false || !!document.documentMode;

// Edge 20+
export const isEdge = () =>
  (!isIE && !!window.StyleMedia) ||
  /Edg|Edge|EdgiOS|EdgA/i.test(navigator.userAgent);

// Chrome 1 - 71
export const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Blink engine detection
export const isBlink = (isChrome || isOpera) && !!window.CSS;
