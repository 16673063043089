const createAndTriggerDownloadLink = (uri: string, name?: string): void => {
  const link = document.createElement('a');
  link.href = uri;
  link.download = name || '';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  link.remove();
};

export const downloadFileFromURI = (uri: string, name?: string): void => {
  createAndTriggerDownloadLink(uri, name);
};

export const downloadFileFromBlob = (data: Blob, fileName: string): void => {
  const blob = new Blob([data], { type: 'text/plain' });
  const url = window.URL.createObjectURL(blob);
  createAndTriggerDownloadLink(url, fileName);
};
