import { CSSProperties } from 'react';

const appIcon: CSSProperties = {
  height: '26px',
  width: '83px',
};

export default {
  appIcon,
};
