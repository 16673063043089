export const splat = '/*';
export enum AppRoute {
  /* Redirect Screens */
  Root = 'root',
  Redirect = '/',

  /* Welcome */
  Welcome = 'welcome',

  /* Login */
  Login = 'login',

  /* Agent Logout */
  AgentLogout = 'agent-logout',

  /* Password */
  Password = 'password',

  /* IDPs */
  IDPs = 'idps',

  /* Mfa */
  Mfa = 'mfa',

  /* Awaiting Screens */
  NoPermission = 'unauthorized',
  Supervisor = 'supervisor',
  Disabled = 'disabled',
  ApprovalWaiting = 'approvals',
  ApprovalExpired = 'expired',
  ApprovalDenied = 'denied',

  /* Enroll Screens */
  Enroll = 'enroll',
  EnrollPersonalComputer = 'personal-computer',
  EnrollMfa = 'mfa',
  EnrollLegal = 'legal',

  UpdatePassword = 'update-password',
  RequestResetPassword = 'request-password-reset',
  InviteUser = 'invite-user',

  /* Approver */
  Approver = 'approver',
  ApproverPolicy = '/approver/policy/:policyId',

  /* Supervisor Approval Screen */
  SupervisorApproval = 'supervisor-approval',

  /* Notify Idp */
  NotifyIdp = 'notify-idp',
}
