import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

import { MfaId } from '@frontend-monorepo/cyolo-auth';
import {
  Button,
  Card,
  CyTheme,
  Divider,
  Text,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';
import { tryParsingBackendError } from '@frontend-monorepo/http-client';

import { useRootStore } from '../../../hooks/useRootStore';
import { AppRoute } from '../../../routes';

const useStyles = createUseStyles((theme: CyTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    gap: 30,
    width: 350,
    padding: '40 50',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    textAlign: 'center',
    alignSelf: 'center',
    width: 350,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  button: {
    width: 160,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '20',

    '& a': {
      color: theme.palette.primary.color,
      fontFamily: 'sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      textAlign: 'center',
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
}));

const MethodStep: FC = () => {
  const {
    stateStore: { requestPasswordResetState: store },
    uiStore,
  } = useRootStore();

  const theme = useThemeSafe();
  const styles = useStyles({ theme });

  const handleMethodClick = async (method: MfaId) => {
    try {
      uiStore.setLoadingState('pending');
      await store.chooseMethod(method);
    } catch (e) {
      uiStore.showToast(
        tryParsingBackendError(e, 'Failed to send code'),
        'refused',
      );
    } finally {
      uiStore.setLoadingState('done');
    }
  };

  return (
    <Card className={styles.root}>
      <div className={styles.header}>
        <Text variant="login-sub-title">Request Password Reset</Text>
        <Text variant="body">Choose how to reset your password*</Text>
      </div>
      <div className={styles.buttons}>
        {store.availableMethods?.map((method) => {
          return (
            <Button
              key={method}
              className={styles.button}
              variant="primary"
              disabled={false}
              onClick={() => {
                handleMethodClick(method);
              }}
            >
              {`Reset via ${method.toUpperCase()}`}
            </Button>
          );
        })}
      </div>
      <div className={styles.header}>
        <Divider orientation="horizontal" thickness={1} />
        <Text variant="body" color={theme.palette.grey.color}>
          *Email or SMS can be used if either a mobile phone number or email
          address have been configured by the administrator.
        </Text>
      </div>
      <div className={styles.footer}>
        <Link to={AppRoute.Login}> Cancel </Link>
        <a onClick={store.backStep}> Back </a>
      </div>
    </Card>
  );
};

export default MethodStep;
