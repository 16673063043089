import { makeAutoObservable } from 'mobx';

import LegalDoc from '../../../model/legal-doc';
import AuthAPI from '../../../services/api/auth';
import DataStoreContainer from '../../data/data';

export default class LegalViewState {
  constructor(private readonly dataStores: DataStoreContainer) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get showLoader(): boolean {
    return this.dataStores.legalDocStore.state === 'pending';
  }

  get legalDoc(): LegalDoc {
    return LegalDoc.fromRaw(this.dataStores.legalDocStore.data);
  }

  async handleAccept(): Promise<void> {
    await AuthAPI.postLegalDocumentationResponse(true);
  }

  async handleDecline(): Promise<void> {
    await AuthAPI.postLegalDocumentationResponse(false);
  }
}
