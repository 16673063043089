import {
  getSystemUniqueID,
  UniqueID,
  UniqueIdentifiable,
} from '@frontend-monorepo/cyolo-utils';

import { IHasSystemUniqueID } from '../interface';

export class Model implements IHasSystemUniqueID, UniqueIdentifiable {
  constructor(public id: string, public kind: string) {}

  /**
   * system-wide unique identifier
   */
  get systemUniqueID(): UniqueID {
    return getSystemUniqueID(this);
  }
}
