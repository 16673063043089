import { makeAutoObservable } from 'mobx';

import { DataState } from '@frontend-monorepo/cyolo-store';

import { AuthProvider } from '../../model';
import AuthAPI from '../../services/api/auth';
import { DynamicLoginResult } from '../../services/api/auth/api';
import { LoginIdentityResponse } from '../../services/api/auth/response';

import { Fetchable } from './data';

interface UserIdentity {
  username: string;
  allowedIdps: AuthProvider[];
  loginEndpoint: string;
  passwordProvider?: AuthProvider;
}

export enum LoginEndpoint {
  Password = '/login/challenge/password',
  IDPs = '/login/idps',
}

export interface Idps {
  passwordIdps: AuthProvider[];
  externalIdps: AuthProvider[];
}

export const ANONYMOUS_USER = 'anonymous';

class UserIdentityStore implements Fetchable {
  data: UserIdentity;
  state: DataState = 'pending';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.data = {
      username: '',
      allowedIdps: [],
      loginEndpoint: '',
      passwordProvider: undefined,
    };
  }

  async fetch(): Promise<void> {
    this.state = 'pending';

    try {
      const { username: lastLoggedInUsername } =
        await AuthAPI.getLastLoggedInUser();

      if (lastLoggedInUsername !== ANONYMOUS_USER) {
        this.data.username = lastLoggedInUsername;
      }

      if (this.data.username) {
        const loginIdentity = await AuthAPI.postLoginIdentity(
          this.data.username,
        );

        this.fromRaw(loginIdentity);
      }

      this.state = 'done';
    } catch (error) {
      this.state = 'error';
    }
  }

  fromRaw(loginIdentityResponse: LoginIdentityResponse) {
    this.data.allowedIdps = loginIdentityResponse.allowed_idps.map((provider) =>
      AuthProvider.fromRaw(provider),
    );

    this.data.loginEndpoint = loginIdentityResponse.auth_url;

    this.data.passwordProvider =
      this.data.allowedIdps.length === 1
        ? this.data.allowedIdps.find((allowedIdp) => allowedIdp.submitForm)
        : undefined;
  }

  get filteredVisibleIdps(): AuthProvider[] {
    return this.data.allowedIdps.filter((idp) => idp.visible);
  }

  get isLocalUser(): boolean {
    if (
      !this.data.username ||
      this.data.loginEndpoint !== LoginEndpoint.Password ||
      this.data.allowedIdps.length !== 1
    ) {
      return false;
    }

    return true;
  }

  get isExternalUser(): boolean {
    if (!this.data.username || this.data.loginEndpoint !== LoginEndpoint.IDPs) {
      return false;
    }

    return true;
  }

  get hasOneVisibleIdp(): boolean {
    return this.filteredVisibleIdps.length === 1;
  }

  get singleExternalIdp(): AuthProvider | null {
    if (this.isExternalUser && this.data.allowedIdps.length === 1) {
      return this.data.allowedIdps[0];
    }

    if (this.isExternalUser && this.hasOneVisibleIdp) {
      return this.filteredVisibleIdps[0];
    }

    return null;
  }

  get externalUserProviders(): Idps | null {
    if (!this.isExternalUser) {
      return null;
    }

    return this.filteredVisibleIdps.reduce<Idps>(
      (accum, idp) => {
        idp.submitForm
          ? accum.passwordIdps.push(idp)
          : accum.externalIdps.push(idp);

        return accum;
      },
      {
        passwordIdps: [],
        externalIdps: [],
      },
    );
  }

  setUsername(username: string) {
    this.data.username = username;
  }

  setPasswordProvider(provider: AuthProvider) {
    this.data.passwordProvider = provider;
  }

  authenticateUser(pass: string): Promise<DynamicLoginResult> {
    if (!this.data.passwordProvider) {
      throw new Error('passwordProvider is not defined');
    }

    return AuthAPI.postDynamicLoginRequest(
      this.data.passwordProvider.url,
      this.data.username,
      pass,
    );
  }

  async clearSelectedUserIdentity(): Promise<void> {
    await AuthAPI.postClearUsernameFromCookies();

    this.data = {
      username: '',
      allowedIdps: [],
      loginEndpoint: '',
      passwordProvider: undefined,
    };
  }
}

export default UserIdentityStore;
