import React, { FC, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

import { observer } from 'mobx-react';

import { Card, CyTheme, Text, useThemeSafe } from '@frontend-monorepo/cyolo-ui';

import { AppRoute } from '../../../../routes';
import pageRedirect from '../../../../services/page-redirect';

interface Props {
  loggedFromBoth: boolean;
}

const redirectInterval = 5000;

const AgentLoggedOut: FC<Props> = ({ loggedFromBoth }) => {
  const theme = useThemeSafe();

  const styles = useStyles({ theme });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      pageRedirect.performPageRedirect(AppRoute.Login);
    }, redirectInterval);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Card className={styles.card}>
      <div className={styles.content}>
        <Text variant="login-sub-title" className={styles.title}>
          You&apos;ve Been Logged Out
        </Text>
        <Text variant="body">
          {loggedFromBoth
            ? 'Both Cyolo Connect and Applications Portal environments are logged out.'
            : 'Cyolo Connect is logged out. '}
          You will be redirected to your portal momentarily. if not,{' '}
          <Link className={styles.loginLink} to="/">
            <Text variant="body" className={styles.linkText}>
              click here.
            </Text>
          </Link>
        </Text>
      </div>
    </Card>
  );
};

export default observer(AgentLoggedOut);

const useStyles = createUseStyles((theme: CyTheme) => ({
  card: {
    display: 'flex',
    justifyContent: 'center',
    width: 500,
    height: 174,
    flexDirection: 'column',
    gap: '30px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    margin: 'auto',
    width: 340,
    height: 94,
  },
  title: {
    width: 340,
    marginBottom: 8,
  },
  loginLink: {
    textDecoration: 'none',
    display: 'inline-block',
  },
  linkText: {
    color: theme.palette.primary.color,
  },
}));
