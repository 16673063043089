import { makeAutoObservable } from 'mobx';

class NewPasswordState {
  /**
   * the new password the user wish to use.
   */
  newPassword: string;

  /**
   * confirmation for the new password.
   */
  confirmPassword: string;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.newPassword = '';
    this.confirmPassword = '';
  }

  /**
   * setNewPassword set the new password.
   * @param password
   */
  setNewPassword(password: string) {
    this.newPassword = password;
  }

  /**
   * setConfirmPassword set the confirm password.
   * @param password
   */
  setConfirmPassword(password: string) {
    this.confirmPassword = password;
  }
}

export default NewPasswordState;
