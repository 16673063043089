import { AxiosError } from 'axios';
import qs from 'qs';

import { MfaId, MfaMethod } from '@frontend-monorepo/cyolo-auth';
import { IUserObject } from '@frontend-monorepo/cyolo-identity';
import { HttpStatusCode } from '@frontend-monorepo/cyolo-store';
import httpClient from '@frontend-monorepo/http-client';

import {
  AuthData,
  AuthProvider,
  EnrollmentRequirement,
  PasswordPolicy,
} from '../../../model';

import Endpoints from './endpoints';
import {
  EnrollmentRequirementResponse,
  ExternalMFAResponse,
  InitiateSmsRawResponse,
  InitiateSmsResponse,
  LoginIdentityResponse,
  LoginResponse,
  MfaMethodResponse,
  ProviderResponse,
  QrCodeResponse,
  UserWelcomeResponse,
} from './response';

const getAuthData = async (): Promise<AuthData> => {
  const endpoint = '/v1/users/me';

  const { data } = await httpClient.get<IUserObject>(endpoint, {
    validateStatus: (status) => {
      // if we get a 403 we ignore it explicitly
      return (
        status < HttpStatusCode.ClientErrorCodeStart ||
        status === HttpStatusCode.UserAnon
      );
    },
  });

  return AuthData.fromRaw(data);
};

const getEnrollmentRequirements = async (): Promise<
  EnrollmentRequirement[]
> => {
  const { data } = await httpClient.get<EnrollmentRequirementResponse[]>(
    Endpoints.GET_EnrollmentRequirements,
  );
  return data.map((id) => new EnrollmentRequirement(id));
};

const getUserMfaMethods = async (): Promise<MfaMethod[]> => {
  const { data } = await httpClient.get<MfaMethodResponse[]>(
    Endpoints.GET_UserMfaMethods,
  );
  return data.map(MfaMethod.fromRaw);
};

const getAuthProviders = async (): Promise<AuthProvider[]> => {
  const { data } = await httpClient.get<ProviderResponse[]>(
    Endpoints.GET_AuthProviders,
  );
  return data.map(
    ({
      id,
      submitForm,
      icon,
      name,
      url,
      allowed_mfa_methods,
      password_policy,
      visible,
    }) =>
      new AuthProvider(
        String(id),
        submitForm,
        icon,
        url,
        name,
        allowed_mfa_methods.map((method) => MfaMethod.fromRaw(method)),
        PasswordPolicy.fromRaw(password_policy),
        visible,
      ),
  );
};

const performMfaEnrollmentCodeValidation = async (
  type: MfaId,
  code: string,
) => {
  switch (type) {
    case MfaId.SMS:
      await postSmsMfaEnrollmentRequest(code);
      break;
    case MfaId.TOTP:
      await postTotpMfaEnrollmentRequest(code);
      break;
    case MfaId.EMAIL:
      await postEmailMfaEnrollmentRequest(code);
      break;
  }
};

const getQrCodeUri = async (): Promise<string> => {
  const { data } = await httpClient.get<QrCodeResponse>(
    Endpoints.GET_QrCodeUri,
  );
  const { uri } = data;
  return uri;
};

const postLegacyLoginRequest = async (
  username: string,
  password: string,
): Promise<LoginResponse> => {
  try {
    const { data } = await httpClient.post<LoginResponse>(
      Endpoints.Login,
      qs.stringify({
        username,
        password,
      }),
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
    return data;
  } catch (err) {
    if (err instanceof AxiosError) {
      const { error = 'user login failed' } = err.response?.data;
      throw Error(error);
    }
    throw Error('user login failed');
  }
};

export interface DynamicLoginResult {
  shouldPerformRedirection: boolean;
}

const postDynamicLoginRequest = async (
  url: string,
  username: string,
  password: string,
): Promise<DynamicLoginResult> => {
  try {
    await httpClient.post(
      url,
      qs.stringify({
        username,
        password,
      }),
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  } catch (error) {
    // if the response is 403 then indicate through the result we need to redirect
    if (error instanceof AxiosError && error?.response?.status === 403) {
      return {
        shouldPerformRedirection: true,
      };
    }

    throw error;
  }

  return {
    shouldPerformRedirection: false,
  };
};

const postLoginIdentity = async (
  username: string,
): Promise<LoginIdentityResponse> => {
  const { data } = await httpClient.post(
    `${window.location.origin}/${Endpoints.POST_LoginIdentity}`,
    qs.stringify({ username }),
    {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

  return data;
};

const getLastLoggedInUser = async (): Promise<UserWelcomeResponse> => {
  const { data } = await httpClient.get<UserWelcomeResponse>(
    Endpoints.GET_LastLoggedInUser,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      validateStatus: (status) => {
        // if we get a 403 we ignore it explicitly
        return (
          status < HttpStatusCode.ClientErrorCodeStart ||
          status === HttpStatusCode.UserAnon
        );
      },
    },
  );

  return data;
};

const postClearUsernameFromCookies = async (): Promise<void> => {
  await httpClient.post(Endpoints.POST_ClearUserFromCookies);
};

export type TTotpOrigin = 'totp' | 'email' | 'sms';
const postTotpConfirmationCode = async (
  code: unknown,
  origin: TTotpOrigin,
  policyId?: string,
) => {
  let url: string = Endpoints.POST_MfaLoginConfirmation;
  if (policyId) {
    url = `${url}/policy/${policyId}`;
  }
  await httpClient.post(
    url,
    qs.stringify({
      [origin.toString()]: code,
    }),
    {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
};

export interface ExternalMfaResult {
  remainingTime: number; // timeout between requests
  successfullySent: boolean;
  magicLink: boolean; // indicates if a magic link was sent instead of a code
  code: string | null; // the code that should be used for validation on mobile
}

export interface InitiateEmailMessageResult {
  // the initiate sms timeout delta milliseconds
  remainingTime: number;
  successfullySent: boolean;
}

const postRequestExternalMFACode = async (
  type: MfaId.EMAIL | MfaId.SMS | MfaId.TOTP,
  policyId?: string,
): Promise<ExternalMfaResult> => {
  let url: string = Endpoints.POST_InitiateMfaSmsMessage;
  if (policyId) {
    url = `${url}/policy/${policyId}`;
  }
  try {
    const { data } = await httpClient.post<ExternalMFAResponse>(
      url,
      { type },
      { withCredentials: true },
    );

    // persist the polling endpoint url to session storage
    if (Boolean(data.pollingUrl)) {
      sessionStorage.setItem('auth_data_polling_endpoint', data.pollingUrl);
    }

    return {
      magicLink: Boolean(data.pollingUrl),
      remainingTime: data.remainingTime,
      successfullySent: true,
      code: data.code,
    };
  } catch (error) {
    // generic error
    if (error instanceof AxiosError && error?.response?.status === 429) {
      // axios error with status code 429
      const { data } = error.response;
      // return the result
      const { remainingTime, pollingUrl, code } = data as ExternalMFAResponse;
      return {
        magicLink: Boolean(pollingUrl),
        remainingTime: remainingTime,
        successfullySent: false,
        code: code,
      };
    }

    throw error;
  }
};

const postPhoneNumberSubmission = async (
  phoneNumber: string,
): Promise<InitiateSmsResponse> => {
  const phoneNumberWithNoSpaces = phoneNumber.replace(' ', '');

  try {
    const {
      data: { remainingTime },
    } = await httpClient.post<InitiateSmsRawResponse>(
      Endpoints.POST_SubmitPhoneNumber,
      {
        phone_number: phoneNumberWithNoSpaces,
      },
    );
    return {
      remainingTime,
      didSucceed: true,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      const { remainingTime = undefined } = err.response?.data;
      return {
        didSucceed: false,
        remainingTime,
      };
    }

    return {
      didSucceed: false,
    };
  }
};

const postEmailSubmission = async (email: string) => {
  try {
    const {
      data: { remainingTime },
    } = await httpClient.post<InitiateSmsRawResponse>(
      Endpoints.POST_SubmitInitiateEmail,
      {
        email,
      },
    );
    return {
      remainingTime,
      didSucceed: true,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      const { remainingTime = undefined } = err.response?.data;
      return {
        didSucceed: false,
        remainingTime,
      };
    }

    return {
      didSucceed: false,
    };
  }
};

const postSmsMfaEnrollmentRequest = async (validationCode: string) => {
  const data = { kind: MfaId.SMS, code: validationCode };
  await httpClient.post(Endpoints.POST_EnrollWithMfaCode, data, {});
};

const postTotpMfaEnrollmentRequest = async (validationCode: string) => {
  const data = { kind: MfaId.TOTP, code: validationCode };
  await httpClient.post(Endpoints.POST_EnrollWithMfaCode, data, {});
};

const postEmailMfaEnrollmentRequest = async (validationCode: string) => {
  const data = { kind: MfaId.EMAIL, code: validationCode };
  await httpClient.post(Endpoints.POST_EnrollWithMfaCode, data, {});
};

const postSaveUser = async () => {
  await httpClient.post(Endpoints.POST_SaveUser, {});
};

const putUserComputerName = async (computerName: string) => {
  await httpClient.put(Endpoints.PUT_UpdateComputerName, {
    personal_desktop: computerName,
  });
};

const getUserProviders = async (username: string): Promise<AuthProvider> => {
  const { data } = await httpClient.get<AuthProvider>(
    Endpoints.GET_UserProviders,
    {
      data: { username },
    },
  );

  return data;
};

const postLegalDocumentationResponse = async (
  consented: boolean,
): Promise<void> => {
  await httpClient.post(Endpoints.POST_LegalDocumentationConsent, {
    consented,
  });
};

export default {
  getQrCodeUri,
  getUserMfaMethods,
  getAuthProviders,
  getEnrollmentRequirements,
  postRequestExternalMFACode,
  postLegacyLoginRequest,
  postTotpConfirmationCode,
  postEmailSubmission,
  postPhoneNumberSubmission,
  performMfaEnrollmentCodeValidation,
  postSaveUser,
  getAuthData,
  putUserComputerName,
  postEmailMfaEnrollmentRequest,
  postLoginIdentity,
  postDynamicLoginRequest,
  getUserProviders,
  getLastLoggedInUser,
  postClearUsernameFromCookies,
  postLegalDocumentationResponse,
};
