import React, { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

import {
  Button,
  CyTheme,
  Icon,
  IconID,
  LoadingIndicator,
  StandaloneLoadingIndicator,
  Text,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';

import { useRootStore } from '../../../../../hooks';
import masker from '../../../../../utils/masker';
import { DigitsInput } from '../../../../shared';

const rowWidth = 336;
const useStyles = createUseStyles((theme: CyTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '1 0 auto',
    minWidth: 340,
    minHeight: 180,
    padding: `48px`,
  },
  title: {
    margin: 24,
  },
  digitsInputSection: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  digitsInputTitle: {
    marginBottom: 16,
  },
  digitsInputContainer: {
    width: rowWidth,
    display: 'flex',
    justifyContent: 'center',
    '& input[type="text"]': {
      flex: '0 0 auto !important',
      width: '20px !important',
    },
    marginBottom: 24,
  },
  invalidGesture: {
    animation: 'shake 0.4s 2',
  },
  resendSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 8,
    height: 48,
    marginTop: '16px',
  },
  codeDigits: {
    display: 'flex',
    gap: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: `${theme.typography.h4.fontSize}`,
    border: `1px solid ${theme.palette.secondary.color}`,
    borderRadius: `${theme.shape.borderRadiusLight}`,
    minWidth: 64,
    minHeight: 48,
  },
  linkText: {
    color: theme.palette.primary.color,
    cursor: 'pointer',
  },
  '@keyframes': {
    shake: {
      '20%': {
        transform: 'translateX(-4px)',
      },

      '40%': {
        transform: 'translateX(0)',
      },

      '60%': {
        transform: 'translateX(4px)',
      },

      '80%': {
        transform: 'translateX(0)',
      },
    },
  },
}));

export type MFAMethod = 'Email' | 'SMS' | 'Totp' | 'Unknown';

interface MfaResolverProps {
  method: MFAMethod;
  magicLinkEnabled: boolean;
  mobileAuthenticatorEnabled?: boolean;
  onSubmit: (code: string) => Promise<boolean>;
  isValid: boolean;
  onCodeRequest?: () => Promise<void>;
  onCancel?: () => void;
  timeoutOver?: boolean;
}

const MfaResolver: FC<MfaResolverProps> = ({
  method,
  onCodeRequest,
  onCancel,
  onSubmit,
  isValid,
  magicLinkEnabled,
  mobileAuthenticatorEnabled,
  timeoutOver,
}) => {
  const theme = useThemeSafe();
  const styles = useStyles({ theme });
  const {
    dataStores: {
      authDataStore: authStore,
      userIdentityStore: userIdentityStore,
    },
    stateStore: { mfaScreenState },
  } = useRootStore();

  const [invalid, setInvalid] = useState(false);

  const triggerInvalidGesture = () => {
    setInvalid(true);
    const timeout = setTimeout(() => {
      setInvalid(false);
      clearTimeout(timeout);
    }, 250);
  };

  useEffect(() => {
    // on page load a request
    if (!onCodeRequest) {
      return;
    }

    onCodeRequest?.();
  }, []);

  useEffect(() => {
    if (isValid) {
      return;
    }
    triggerInvalidGesture();
  }, [isValid]);

  const resolveIconID = (): IconID => {
    if (timeoutOver) return 'timeout';
    if (mobileAuthenticatorEnabled) return 'authenticator-auto';

    switch (method) {
      case 'SMS':
        return 'sms';
      case 'Totp':
        return 'authenticator';
      case 'Email':
        return 'email';
      default:
        return 'verification';
    }
  };

  const resolveTitle = () => {
    if (timeoutOver) return 'The Request Timed Out';
    return `Welcome ${userIdentityStore.data.username}`;
  };

  const resolveSubtitle = () => {
    if (timeoutOver) return 'You’re being timed out due to inactivity.';
    if (magicLinkEnabled) return `A link was sent to you through ${method}`;
    if (mobileAuthenticatorEnabled && mfaScreenState.getCode() !== '')
      return 'Open Cyolo Connect app, and enter the number shown to sign in.';

    switch (method) {
      case 'Email':
        return `Enter the code from the email sent to ${masker.maskEmail(
          authStore.data.userEmail,
        )}`;
      case 'SMS':
        return `Enter the code from the SMS message sent to ${masker.maskPhoneNumber(
          authStore.data.userPhone,
        )}`;
      case 'Totp':
        return 'Enter the code displayed in your authenticator app';
    }

    return 'Enter code';
  };

  const renderInput = () => {
    if (mobileAuthenticatorEnabled && mfaScreenState.getCode() !== '')
      return (
        <Text className={styles.codeDigits} variant="header-2">
          {mfaScreenState.getCode()}
        </Text>
      );

    if (magicLinkEnabled)
      return (
        <>
          <StandaloneLoadingIndicator color="blue" />
        </>
      );

    return (
      <div
        className={clsx(styles.digitsInputContainer, {
          [styles.invalidGesture]: invalid,
        })}
      >
        <DigitsInput
          digitFontSize={theme.typography.h4.fontSize}
          key="digits-input"
          numberOfKeys={6}
          large={true}
          onCompletion={(code) => {
            return onSubmit(code);
          }}
        />
      </div>
    );
  };

  const renderRetry = () => {
    return (
      <div className={styles.resendSection}>
        <Button variant="primary" onClick={onCancel}>
          Return to Login
        </Button>
        <Text
          variant="body"
          className={styles.linkText}
          onClick={onCodeRequest}
        >
          Resend request
        </Text>
      </div>
    );
  };

  const renderContent = () => {
    if (method === 'Unknown') return <LoadingIndicator color="blue" />;

    return (
      <div className={styles.digitsInputSection}>
        <Icon size={54} id={resolveIconID()} />
        <Text variant="login-sub-title">{resolveTitle()}</Text>
        <div className={styles.digitsInputTitle}>
          <Text variant="body" component="div">
            {resolveSubtitle()}
          </Text>
        </div>

        {!timeoutOver && renderInput()}
        {timeoutOver && renderRetry()}
      </div>
    );
  };

  return <div className={styles.root}>{renderContent()}</div>;
};

export default MfaResolver;
