import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import { observer } from 'mobx-react';

import { CyTheme, Text, useThemeSafe } from '@frontend-monorepo/cyolo-ui';

interface WelcomeUserProps {
  username: string;
}

const WelcomeUser: FC<WelcomeUserProps> = ({ username }) => {
  const theme = useThemeSafe();
  const styles = useStyles({ theme });

  return (
    <div>
      <Text variant="login-sub-title" className={styles.welcomeUser}>
        Welcome {username}
      </Text>
    </div>
  );
};

export default observer(WelcomeUser);

const useStyles = createUseStyles((theme: CyTheme) => ({
  welcomeUser: {
    textAlign: 'center',
    marginTop: 8,
    marginLeft: 16,
    marginRight: 16,
  },
}));
