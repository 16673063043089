import React, { FC, useMemo } from 'react';
import Logo from 'Logo/Logo';

import { observer } from 'mobx-react';

import { hasCompanyInfoStore } from '@frontend-monorepo/cyolo-company';
import { hasUserStore } from '@frontend-monorepo/cyolo-identity';
import {
  ActionIconProps,
  MinimalNavBar,
  MinimalNavBarMobile,
} from '@frontend-monorepo/cyolo-ui';

import { isMobile } from '../hooks/useWindowSize';

interface UserFacingNavbarProps {
  rootStore: hasUserStore & hasCompanyInfoStore;
  onOpenVaultClick?: () => void;
  onOpenMenuClick?: () => void;
  onUpdatePassword?: () => void;
  onCyoloConnectClick?: () => void;
  onAdminPortalClick?: () => void;
  onRecordingsClick?: () => void;
  onSupervisorClick?: () => void;
  onPersonalInfoClick?: () => void;
  onCommandLineClick?: () => void;
  onAccessPageClick?: () => void;
  onApplicationsConsoleClick?: () => void;
  onFileTransferClick?: () => void;
  title?: string;
}

const UserFacingNavbar: FC<UserFacingNavbarProps> = ({
  rootStore: { userStore, companyInfoStore },
  onOpenVaultClick,
  onOpenMenuClick,
  onUpdatePassword,
  onCyoloConnectClick,
  onAdminPortalClick,
  onRecordingsClick,
  onSupervisorClick,
  onPersonalInfoClick,
  onCommandLineClick,
  onAccessPageClick,
  onApplicationsConsoleClick,
  onFileTransferClick,
  title = '',
}) => {
  const userDropDownSections = useMemo(() => {
    const firstSection: ActionIconProps[] = [];

    if (onAdminPortalClick !== undefined) {
      firstSection.push({
        text: 'Admin Portal',
        onClick: onAdminPortalClick,
      });
    }

    if (onApplicationsConsoleClick !== undefined) {
      firstSection.push({
        text: 'Applications Portal',
        onClick: onApplicationsConsoleClick,
      });
    }

    if (onAccessPageClick !== undefined) {
      firstSection.push({
        text: 'My Access Requests',
        onClick: onAccessPageClick,
      });
    }

    if (onRecordingsClick !== undefined) {
      firstSection.push({
        text: 'Recordings',
        onClick: onRecordingsClick,
      });
    }

    if (onSupervisorClick !== undefined) {
      firstSection.push({
        text: 'Supervisor',
        onClick: onSupervisorClick,
      });
    }

    if (onFileTransferClick !== undefined) {
      firstSection.push({
        text: 'Secure File Transfer',
        onClick: onFileTransferClick,
      });
    }

    const secondSection: ActionIconProps[] = [];
    if (onOpenVaultClick !== undefined) {
      secondSection.push({
        text: 'User Secrets',
        onClick: onOpenVaultClick,
      });
    }

    if (onUpdatePassword !== undefined) {
      secondSection.push({
        text: 'Change Password',
        onClick: onUpdatePassword,
      });
    }

    if (onPersonalInfoClick !== undefined) {
      secondSection.push({
        text: 'Personal Info & Security',
        onClick: onPersonalInfoClick,
      });
    }

    if (onCyoloConnectClick !== undefined) {
      secondSection.push({
        text: 'Download Cyolo Connect',
        onClick: onCyoloConnectClick,
      });
    }

    if (onCommandLineClick !== undefined) {
      secondSection.push({
        text: 'Download Cyolo CLI',
        onClick: onCommandLineClick,
      });
    }

    const thirdSection: ActionIconProps[] = [
      {
        text: 'Logout',
        onClick: (): void => {
          window.location.href = '/cyolo/v1/logout';
        },
      },
    ];

    return [firstSection, secondSection, thirdSection];
  }, [
    onOpenVaultClick,
    onUpdatePassword,
    onCyoloConnectClick,
    onAdminPortalClick,
    onRecordingsClick,
    onSupervisorClick,
    onPersonalInfoClick,
  ]);

  if (isMobile()) {
    return (
      <MinimalNavBarMobile
        userName={userStore.data?.name}
        userEmail={userStore.data?.email}
        firstName={userStore.data?.firstName}
        lastName={userStore.data?.lastName}
        onMenuClick={onOpenMenuClick}
        title={title}
        userDropdownSections={userDropDownSections}
      />
    );
  }

  return (
    <MinimalNavBar
      logo={<Logo rootStore={{ companyInfoStore }} type="white" />}
      variant="user-nav"
      userName={userStore.data?.name}
      userEmail={userStore.data?.email}
      firstName={userStore.data?.firstName}
      lastName={userStore.data?.lastName}
      userDropdownSections={userDropDownSections}
      title={title}
    />
  );
};

export default observer(UserFacingNavbar);
