import ms from 'ms';

import { reaction, when } from 'mobx';

import { AppRoute } from '../routes';
import { ApprovalState } from '../services/api/response';
import DataStoreContainer from '../stores/data/data';
import LocationStore from '../stores/location-store';

const AuthDataPollingInterval = ms('2s');
const ApprovalPollingInterval = ms('2s');

// LocationDataLoader is in charge of data fetching coordination from the different stores
// based on the current app location (path wise)
class LocationDataLoader {
  private intervalsIdsArray: NodeJS.Timeout[] = [];

  cleanUp = (): void => {
    // clear all intervals on location change
    this.intervalsIdsArray.forEach((id) => clearInterval(id));
  };

  startPollingAuthData = (): void => {
    // start polling for auth data
    const id = setInterval(() => {
      this.data.authDataStore.fetch();
    }, AuthDataPollingInterval);
    // push the interval id to the intervals id array
    this.intervalsIdsArray.push(id);
  };

  startPollingApprovalState = (): void => {
    // start polling approval status by id
    const id = setInterval(() => {
      this.data.approvalStore.fetch();
    }, ApprovalPollingInterval);
    // push the interval id to the intervals id array
    this.intervalsIdsArray.push(id);
  };

  constructor(
    private locationStore: LocationStore,
    private data: DataStoreContainer,
  ) {
    // autorun on location change and fetch data for the relevant screens
    reaction(
      () => this.locationStore.currentAppRoute,
      () => {
        this.cleanUp();

        switch (this.locationStore.currentAppRoute) {
          // location for www.myHost.com & www.myHost.com/
          case undefined:
          case AppRoute.Redirect:
            data.authProvidersStore.fetch();
            data.companyInfoStore.fetch();
            data.authDataStore.fetch();
            data.userStore.fetch();
            data.userIdentityStore.fetch();
            break;
          case AppRoute.Login:
            this.data.fetch('approvalStore');
            break;
          case AppRoute.AgentLogout:
            data.urlsStore.fetch();
            break;
          case AppRoute.Enroll:
          case AppRoute.EnrollMfa:
          case AppRoute.EnrollPersonalComputer:
          case AppRoute.EnrollLegal:
            data.enrollmentRequirementsStore.fetch();
            data.organizationMfaMethodsStore.fetch();
            data.userIdentityStore.fetch();
            data.userMfaMethodsStore.fetch();
            data.legalDocStore.fetch();
            break;
          case AppRoute.Mfa:
            data.userMfaMethodsStore.fetch();
            break;
          case AppRoute.Supervisor:
            // start polling for auth data
            this.startPollingAuthData();
            break;
          case AppRoute.ApprovalWaiting:
            when(
              () => data.approvalStore.state !== ApprovalState.prePending,
              () => {
                this.startPollingApprovalState();
              },
            );
            break;
          case AppRoute.SupervisorApproval:
            data.supervisorApprovalStore.fetchSupervisorApprovalToken();
            break;
          case AppRoute.RequestResetPassword:
            data.userIdentityStore.fetch();
            break;
          case AppRoute.InviteUser:
            data.userIdentityStore.fetch();
            break;
          default:
            console.log(
              '[location data loader] no match found. route=',
              this.locationStore.currentAppRoute,
            );

            break;
        }
      },
      { fireImmediately: true },
    );
  }
}

export default LocationDataLoader;
