import emailValidator from 'email-validator';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import validUrl from 'valid-url';

import Static from './static';

const CLOCK_HOURS_MINUTES_REGEX = /^(1[0-9]|2[0-3]|0[0-9]|[1-9]):[0-5][0-9]$/;
const NUMBERS_REGEX = /^-?[0-9]+$/;
const IPV4_REGEX =
  /^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

const CIDRV4_REGEX =
  /^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))$/;

const CIDR_V6_REGEX =
  /^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$/;

const DNS_REGEX = /^(?!-)[*A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/;

const VALID_PART_NUMBER = 3;

type TValidator = (v: string) => boolean;
// checks if a given value is a local asset DOM string (its a url that starts with blob:)
const domString: TValidator = (value) => {
  return value.startsWith('blob:');
};

//checks value is not an empty string or just white spaces
const notEmpty: TValidator = (value) => {
  return Boolean(value) && value.length > 0 && value.trim() !== '';
};

const email = (email: string): boolean => {
  return emailValidator.validate(email);
};

//checks phonenumber is a valid phone number
const phoneNumber: TValidator = (value) => {
  const parsedPhoneNumber = parsePhoneNumberFromString(value);
  if (!parsedPhoneNumber) return false;
  return parsedPhoneNumber.isValid();
};

/**
 * validates a given port range
 * Example:
 * 1-23
 * 2-1
 * @param value
 * @returns
 */
const portRange: TValidator = (value) => {
  return value
    .split('-') // split the range
    .map((val) => val.trim()) // clean each part
    .every(port); // check if part is a valid port
};

/**
 * validates a given value as a single port,
 * multiple port (comma delimited) or even port range
 * Example:
 * 22
 * 22, 80
 * 22, 80-100
 * 22-34,80-100
 * @param value
 * @returns
 */
const complexPorts: TValidator = (value) => {
  const isEmpty = !Boolean(value);
  if (isEmpty) {
    return false;
  }

  // we split the value by a comma and trim all
  // extra whitespace to validate the values consistently
  const parts = value.split(',').map((part) => part.trim());
  for (const part of parts) {
    // check if part is port range & if its valid
    if (part.includes('-')) {
      if (!portRange(part)) {
        return false;
      }
      continue;
    }

    // check if part's port value is valid
    if (!port(part)) {
      return false;
    }
  }

  return true;
};

// TODO: consider change arg to number
//checks port value is in correct range
const port: TValidator = (value) => {
  const portNumber = Number(value);
  if (isNaN(portNumber)) return false;

  return (
    portNumber >= Static.PortRange.Min && portNumber <= Static.PortRange.Max
  );
};

/**
 * validates a given CIDR is valid
 * Based On: https://www.regextester.com/93987
 * @param value
 * @returns
 */
const cidr: TValidator = (value) => {
  return CIDRV4_REGEX.test(value) || CIDR_V6_REGEX.test(value);
};

const startsWithSlash: TValidator = (value) => {
  return value.startsWith('/');
};

const address: TValidator = (value) => {
  // https://regexr.com/37i6s
  const addressIsValidDNSWithoutHttpHttps = value.match(
    Static.RegexPatterns.Dns,
  );
  const addressIsValidIp = value.match(Static.RegexPatterns.Ip);

  const addressIsValidIpV6 = value.match(Static.RegexPatterns.IpV6);

  if (
    addressIsValidDNSWithoutHttpHttps ||
    addressIsValidIp ||
    addressIsValidIpV6
  ) {
    return true;
  }

  return false;
};

const url: TValidator = (url) => {
  return Boolean(validUrl.isWebUri(url));
};

const webhook: TValidator = (url) => {
  const matchedUrl = url.match(Static.RegexPatterns.Webhook);
  return !!matchedUrl;
};

/**
 * validates a given DN ldap tree
 * @param dN
 * @returns
 */
const activeDirectoryDN: TValidator = (dN) => {
  // TODO: improve on validation
  return dN.includes('=');
  // const matchedDn = dN.match(Static.RegexPatterns.activeDirectoryDN);
  // if (matchedDn) return true;
  // return false;
};

/**
 * checks that certificate is valid
 * @param cert
 * @returns
 */
const certificate: TValidator = (cert) => {
  // TODO: improve on validation
  return (
    cert.startsWith('-----BEGIN CERTIFICATE-----') &&
    cert.endsWith('-----END CERTIFICATE-----')
  );
};

/**
 * checks if given password follows correct rules: length must be between 8 and 64 chars, has to have 3 of the
 * following rules:
 * 1) 1 uppercase letter
 * 2) 1 lowercase letter
 * 3) 1 symbol OR 1 digit
 * @param password
 * @returns
 */
const complexPassword: TValidator = (password) => {
  if (password.length < Static.MinPasswordLength) return false;
  if (password.length > Static.MaxPasswordLength) return false;

  let passedComplexityChecks = 0;
  const regExes = [
    Static.RegexPatterns.Uppercase,
    Static.RegexPatterns.Lowercase,
    Static.RegexPatterns.Symbols,
    Static.RegexPatterns.Numbers,
  ];

  passedComplexityChecks = regExes.filter((regEx) =>
    regEx.test(password),
  ).length;

  return passedComplexityChecks >= Static.NeededComplexityChecksThreshold;
};

/**
 * checks if given string has only chars,digits and dashes
 * @param value
 * @returns
 */
const applicationAddress: TValidator = (value) => {
  if (value === '') return false;

  const matchedValue = value.match(Static.RegexPatterns.ApplicationAddress);
  if (matchedValue) return true;
  return false;
};

/**
 * checks if a string is a valid cyolo license
 * @param license
 * @returns
 */
const cyoloLicense: TValidator = (license) => {
  const parts = license.split('.');
  if (parts.length !== VALID_PART_NUMBER) return false;

  const [, dataPart] = parts;
  try {
    const data = JSON.parse(atob(dataPart));
    const existingKeys = Object.keys(data);
    return Static.NeededKeys.every((key) => existingKeys.includes(key));
  } catch (error) {
    return false;
  }
};

/**
 * Checks if a given time string is at the template of HH:MM
 * @param time
 */
const clockHourMinutes: TValidator = (time) => {
  return CLOCK_HOURS_MINUTES_REGEX.test(time);
};

/**
 * Check if a given value is greater or equal to 0.
 * Note: input event values are considered as string, therefore the various checks
 * @param value
 */
const positiveIntegerOrZero = (value: number): boolean => {
  if (value === undefined || value === null) return false;
  if (String(value).length === 0) return false;
  if (!NUMBERS_REGEX.test(String(value))) return false;

  const castedNumber = Number(value);
  if (isNaN(castedNumber)) {
    console.error(
      `[positiveIntegerOrZero] - received a NaN value. value: ${value}. type: ${typeof value}`,
    );
    return false;
  }

  return castedNumber >= 0;
};

const ip: TValidator = (value) => {
  return IPV4_REGEX.test(value);
};

const dns: TValidator = (value: string): boolean => {
  return DNS_REGEX.test(value);
};

const domainName: TValidator = (value: string) => {
  return Static.RegexPatterns.DomainName.test(value);
};

const databaseName: TValidator = (value: string) => {
  return Static.RegexPatterns.DatabaseName.test(value);
};

export default {
  applicationAddress,
  notEmpty,
  complexPassword,
  phoneNumber,
  port,
  complexPorts,
  startsWithSlash,
  address,
  activeDirectoryDN,
  url,
  certificate,
  cyoloLicense,
  domString,
  cidr,
  email,
  webhook,
  clockHourMinutes,
  positiveIntegerOrZero,
  ip,
  dns,
  domainName,
  databaseName,
};
