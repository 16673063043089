import { makeAutoObservable } from 'mobx';

import { DataState } from '@frontend-monorepo/cyolo-store';

import API from '../services/api/api';
import { SupervisorApprovalTokenResponse } from '../services/api/response';

type SupervisorApprovalInfo = SupervisorApprovalTokenResponse;

class SupervisorApprovalStore {
  state?: DataState;
  supervisorApprovalInfo: SupervisorApprovalInfo | undefined;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async fetchSupervisorApprovalToken(): Promise<void> {
    // parse out code
    const code = new URLSearchParams(location.search).get('code');

    // verify that approval token is not null
    if (code === null) {
      return;
    }
    this.supervisorApprovalInfo = await API.fetchSupervisorApprovalToken(code);
  }
}

export default SupervisorApprovalStore;
