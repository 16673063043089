import { CSSProperties } from 'react';

const baseToasterStyle: CSSProperties = {
  position: 'absolute',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 0,
  transition: 'all 0.2s ease-in-out',
};

const styles: { [key: string]: CSSProperties } = {
  'bottom-center': {
    ...baseToasterStyle,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  'bottom-left': {
    ...baseToasterStyle,
    bottom: 0,
    left: 0,
  },
  'bottom-right': {
    ...baseToasterStyle,
    bottom: 0,
    right: 0,
  },
  'top-center': {
    ...baseToasterStyle,
    top: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  'top-right': {
    ...baseToasterStyle,
    top: 0,
    right: 0,
  },
  'top-left': {
    ...baseToasterStyle,
    top: 0,
    left: 0,
  },
};

export default styles;
