import parsePhoneNumberFromString from 'libphonenumber-js';

const maskingCharacter = '•';

const maskEmail = (email: string | null) => {
  if (!email) {
    return '';
  }

  const [localPart, domain] = email.split('@');
  if (localPart.length > 2) {
    return (
      localPart[0] +
      maskingCharacter.repeat(9) +
      localPart.slice(-1) +
      '@' +
      domain
    );
  } else {
    return email;
  }
};

const maskPhoneNumber = (phone: string | null) => {
  if (!phone) {
    return '';
  }

  const internationalNumber =
    parsePhoneNumberFromString(phone)?.formatInternational();
  if (!internationalNumber) {
    return phone;
  }

  const maskedNumber =
    internationalNumber
      .slice(0, -2)
      .replace(/\d/g, maskingCharacter)
      .replaceAll(' ', '-') + internationalNumber.slice(-2);
  return maskedNumber;
};

export default {
  maskEmail,
  maskPhoneNumber,
};
