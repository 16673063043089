import { DefaultTimeout } from './../const';
export type ToastType = 'approved' | 'refused';
import { v4 as uuid } from 'uuid';
export interface BaseToast {
  message: string;
  type: ToastType;
}
class Toast implements BaseToast {
  message: string;

  type: ToastType;

  id?: string;

  timeout?: number;

  onSubmit?: () => void;

  submitText?: string;

  constructor(
    id: string = uuid(),
    message: string,
    type: ToastType,
    timeout = DefaultTimeout,
    onSubmit = undefined,
    submitText = '',
  ) {
    this.id = id;

    this.message = message;

    this.type = type;

    this.timeout = timeout;

    this.onSubmit = onSubmit;

    this.submitText = submitText;
  }
}

export default Toast;
