import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

import { observer } from 'mobx-react';

import {
  Button,
  Card,
  CyTheme,
  Text,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';

import { useRootStore } from '../../../../hooks';
import Endpoints from '../../../../services/api/endpoints';
import pageRedirect from '../../../../services/page-redirect';

const AgentAskWebLogout: FC = () => {
  const {
    dataStores: { urlsStore },
  } = useRootStore();
  const theme = useThemeSafe();
  const styles = useStyles({ theme });

  return (
    <Card className={styles.card}>
      <div className={styles.content}>
        <Text variant="login-sub-title" className={styles.title}>
          You&apos;ve Been Logged Out
        </Text>
        <Text variant="body" className={styles.subTitle}>
          Cyolo Connect is logged out. Do you want to log out of Applications
          Portal as well?
        </Text>
        <Button
          variant="primary"
          className={styles.continueBtn}
          onClick={() => {
            pageRedirect.performPageRedirect(Endpoints.logout);
          }}
        >
          Log out of Applications Portal
        </Button>
        <div className={styles.applicationPortalLink}>
          <Link
            to="/agent-logout"
            onClick={() => {
              pageRedirect.performPageRedirect(
                urlsStore.data.applicationsPortal,
              );
            }}
          >
            <Text variant="body" className={styles.linkText}>
              Open Applications Portal
            </Text>
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default observer(AgentAskWebLogout);

const useStyles = createUseStyles((theme: CyTheme) => ({
  card: {
    display: 'flex',
    justifyContent: 'center',
    width: 500,
    height: 237,
    flexDirection: 'column',
    gap: '30px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: 340,
    margin: 'auto',
  },
  continueBtn: {
    width: 222,
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  title: {
    width: 340,
    marginBottom: 8,
  },
  subTitle: {
    marginBottom: 30,
  },
  applicationPortalLink: {
    '& a': {
      color: theme.palette.primary.color,
      textDecoration: 'none',
    },
  },
  linkText: {
    color: theme.palette.primary.color,
  },
}));
