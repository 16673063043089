import httpClient from '@frontend-monorepo/http-client';

import { MfaMethodResponse } from './mfa';

const getOrganizationMfaMethods = async (): Promise<MfaMethodResponse[]> => {
  const { data } = await httpClient.get<MfaMethodResponse[]>(
    '/v1/auth/mfa/methods',
  );
  return data;
};

export default {
  getOrganizationMfaMethods,
};
