import React, { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import httpStatus from 'http-status';

import { observer } from 'mobx-react';

import { MfaId } from '@frontend-monorepo/cyolo-auth';
import {
  Card,
  CyTheme,
  Divider,
  RetryTextAndButton,
  StandaloneLoadingIndicator,
  Text,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';
import { tryParsingBackendError } from '@frontend-monorepo/http-client';

import { useRootStore } from '../../../../hooks';
import { TTotpOrigin } from '../../../../services/api/auth/api';
import Endpoints from '../../../../services/api/endpoints';
import pageRedirect from '../../../../services/page-redirect';
import masker from '../../../../utils/masker';

import MfaMethods from './MfaMethods/MfaMethods';
import MfaResolver, { MFAMethod } from './MfaResolver/MfaResolver';

import './ContentCard.css';

const LoginOptions: FC = () => {
  const {
    uiStore,
    dataStores: {
      authDataStore: authStore,
      userIdentityStore: userIdentityStore,
    },
    stateStore: { mfaScreenState },
  } = useRootStore();
  const theme = useThemeSafe();
  const styles = useStyles({ theme });
  if (authStore.state === 'error') {
    return <RetryTextAndButton action={authStore.fetch} />;
  }

  const [isCodeValid, setIsCodeValid] = useState(true);
  const [isUsingMobile, setIsUsingMobile] = useState(true);
  const allowedMethods: MfaId[] = mfaScreenState.userMethods.map((i) => i.id);

  const [isCodeSubmitInprogress, setIsCodeSubmitInprogress] =
    useState<boolean>(false); // prevent code paste to trigger 2 calls

  const resolveMethodUsingState = (method: MFAMethod): MFAMethod => {
    // only relevant to SMS at this point
    if (!mfaScreenState.canExternalMFATypeBeInferred) return 'Unknown';

    return method;
  };

  const handleCodeSubmit = async (
    code: string,
    origin: TTotpOrigin,
  ): Promise<boolean> => {
    if (isCodeSubmitInprogress) return true;
    try {
      setIsCodeSubmitInprogress(true);
      await mfaScreenState.handleDigitsInputCompletion(code, origin);
      uiStore.showToast('Code verified successfully');
      return true;
    } catch (err) {
      setIsCodeValid(false);
      if (err instanceof Error) {
        if (err.message.includes(String(httpStatus.UNAUTHORIZED))) {
          mfaScreenState.resetVerificationState();
          uiStore.showToast(
            tryParsingBackendError(err, 'Incorrect code'),
            'refused',
          );
        } else {
          uiStore.showToast(
            tryParsingBackendError(err, 'Failed to verify code'),
            'refused',
          );
        }
      }
      return false;
    } finally {
      setIsCodeSubmitInprogress(false);
      setIsCodeValid(true);
    }
  };

  const handleSmsCodeSend = async () => {
    try {
      await mfaScreenState.sendMfaCodeBySMS();
    } catch (err) {
      uiStore.showToast(
        tryParsingBackendError(err, 'Failed to send SMS'),
        'refused',
      );
    }
  };

  const handleEmailCodeSend = async () => {
    try {
      await mfaScreenState.sendMfaCodeByEmail();
    } catch (err) {
      uiStore.showToast(
        tryParsingBackendError(err, 'Failed to send email code'),
        'refused',
      );
    }
  };

  const handleTotpCodeSend = async () => {
    try {
      await mfaScreenState.sendMfaCodeByTotp();
    } catch (err) {
      uiStore.showToast(
        tryParsingBackendError(err, 'Failed to send OTP code'),
        'refused',
      );
    }
  };

  const handleResetMethod = async () => {
    mfaScreenState.selectMfaId(undefined);
  };

  const handleCancel = async () => {
    pageRedirect.performPageRedirect(Endpoints.logout);
  };

  if (mfaScreenState.isSupervised) {
    return (
      <Card>
        <div className="MfaScreen-ContentCardContent">
          <Text variant="body" className={styles.supervisedText}>
            Supervisor has been requested to approve your access
          </Text>
          <StandaloneLoadingIndicator color="blue" />
        </div>
      </Card>
    );
  }

  const renderResetMethod = () => {
    if (mfaScreenState.selectedMfaId === undefined) {
      return null;
    }

    return (
      !mfaScreenState.isCurrentTimeoutOver && (
        <>
          <Divider
            orientation="horizontal"
            thickness={1}
            className={styles.divider}
          />
          <div className={styles.bottomText}>
            <Text
              variant="body"
              className={styles.linkText}
              onClick={handleResetMethod}
            >
              Get a code a different way
            </Text>
          </div>
        </>
      )
    );
  };

  const renderMethod = () => {
    switch (mfaScreenState.selectedMfaId) {
      case MfaId.TOTP:
        return (
          <MfaResolver
            magicLinkEnabled={false}
            mobileAuthenticatorEnabled={
              mfaScreenState.mobileEnrolled && isUsingMobile
            }
            method={resolveMethodUsingState('Totp')}
            isValid={isCodeValid}
            onCodeRequest={handleTotpCodeSend}
            onCancel={handleCancel}
            timeoutOver={mfaScreenState.totpRequestStore.isTimeoutOver}
            onSubmit={(code) => {
              return handleCodeSubmit(code, 'totp');
            }}
          />
        );
      case MfaId.SMS:
        return (
          <MfaResolver
            magicLinkEnabled={mfaScreenState.isMagicLinkEnabled}
            method={resolveMethodUsingState('SMS')}
            isValid={isCodeValid}
            onCodeRequest={handleSmsCodeSend}
            onCancel={handleCancel}
            timeoutOver={mfaScreenState.smsRequestStore.isTimeoutOver}
            onSubmit={(code) => {
              return handleCodeSubmit(code, 'sms');
            }}
          />
        );
      case MfaId.EMAIL:
        return (
          <MfaResolver
            magicLinkEnabled={mfaScreenState.isMagicLinkEnabled}
            method={resolveMethodUsingState('Email')}
            isValid={isCodeValid}
            onCodeRequest={handleEmailCodeSend}
            onCancel={handleCancel}
            timeoutOver={mfaScreenState.emailRequestStore.isTimeoutOver}
            onSubmit={(code) => {
              return handleCodeSubmit(code, 'email');
            }}
          />
        );
      default:
        return (
          <MfaMethods
            username={userIdentityStore.data.username}
            userPhone={masker.maskPhoneNumber(authStore.data.userPhone)}
            totpCode={mfaScreenState.getCode(MfaId.TOTP)}
            isMobileOtpEnabled={mfaScreenState.mobileEnrolled}
            onMfaMethodClick={(id, mobile) => {
              setIsUsingMobile(mobile);
              mfaScreenState.selectMfaId(id);
            }}
            mfaMethods={allowedMethods}
          />
        );
    }
  };

  return (
    <>
      <Card>
        <div className="MfaScreen-ContentCardContent">
          {renderMethod()}
          {renderResetMethod()}
        </div>
      </Card>
    </>
  );
};

export default observer(LoginOptions);

const useStyles = createUseStyles((theme: CyTheme) => ({
  supervisedText: {
    marginBottom: 16,
  },
  divider: {
    display: 'flex',
    width: 392,
    marginRight: 48,
    marginLeft: 48,
    backgroundColor: theme.palette.background.color,
  },
  bottomText: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: 16,
    paddingBottom: 36,
    cursor: 'pointer',
  },
  linkText: {
    color: theme.palette.primary.color,
  },
}));
