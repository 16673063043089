import { FC, useContext, useEffect } from 'react';

import { reaction } from 'mobx';
import { observer } from 'mobx-react';

import { ToastContext } from '@frontend-monorepo/cyolo-toast';

import { useRootStore } from '../../../hooks';

// TODO: write comments

const ToastAdapter: FC = () => {
  // TODO cyolotoast useToast hook that wraps useContext
  const { uiStore } = useRootStore();
  const { showToast } = useContext(ToastContext);
  useEffect(() => {
    const disposable = reaction(
      () => uiStore.activeToast,
      (toast) => {
        if (!toast) return;
        showToast(toast.message, toast.type);
      },
    );
    return (): void => {
      disposable();
    };
  }, []);

  return null;
};

export default observer(ToastAdapter);
