import axios, { AxiosResponse } from 'axios';
import qs from 'qs';

import { PasswordRuleDescription } from '../model';

import { PolicyVerificationResponse, RawPasswordData } from './response';

const PASSWORD_CHANGE_URL = '/v1/users/me/password/change';
const PASSWORD_RESET_URL = '/v1/users/me/password/reset';
const PASSWORD_RESET_INVITED_USER_URL = '/v1/users/me/password/reset/invited';
const PASSWORD_EXPIRATION_URL = '/v1/users/me/password/expiration';
const PASSWORD_VERIFY_URL = '/v1/users/me/password/policy_verification';
const PASSWORD_VERIFY_TOKEN_URL =
  '/v1/users/me/password/policy_verification_token';
const RULE_SET_URL = '/v1/password_ruleset';

const performUpdatePassword = async (
  oldPassword: string,
  newPassword: string,
) => {
  const data = {
    oldPassword: oldPassword,
    newPassword: newPassword,
  };
  await axios.patch(PASSWORD_CHANGE_URL, qs.stringify(data), {
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
  });
};

export const performResetPassword = async (newPassword: string) => {
  await axios.patch(PASSWORD_RESET_URL, qs.stringify({ newPassword }), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
  });
};

// Perform reset password for invited user, behavior is different from normal user.
export const performResetPasswordForInvitedUser = async (
  newPassword: string,
) => {
  await axios.patch(
    PASSWORD_RESET_INVITED_USER_URL,
    qs.stringify({ newPassword }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    },
  );
};

/**
 * gets password data from back-end
 **/
export const getPasswordDaysLeft = async (): Promise<number> => {
  const { data } = await axios.get<RawPasswordData>(PASSWORD_EXPIRATION_URL);

  const expiration = new Date(data.expiration_time * 1000);
  const now = new Date(Date.now());
  return Math.floor(
    (expiration.getTime() - now.getTime()) / (1000 * 3600 * 24),
  );
};

export const getPasswordRules = async () => {
  const { data } = await axios.get<PasswordRuleDescription[]>(RULE_SET_URL);

  return data;
};

export enum ValidateType {
  ResetPassword = 'tokenBased',
  UpdatePassword = 'loginBased',
}

export const validatePassword = async (
  password: string,
  type: ValidateType,
) => {
  const { data } = await axios.post<
    string,
    AxiosResponse<PolicyVerificationResponse[]>
  >(
    type === ValidateType.ResetPassword
      ? PASSWORD_VERIFY_TOKEN_URL
      : PASSWORD_VERIFY_URL,
    password,
    {
      headers: {
        'Content-Type': 'text/plain',
      },
    },
  );

  return data;
};

export default {
  performUpdatePassword,
  performResetPassword,
  performResetPasswordForInvitedUser,
  getPasswordDaysLeft,
  getPasswordRules,
  validatePassword,
};
