import { useCallback, useEffect, useState } from 'react';

export const CONFIRM_PASSWORD_ERROR_MESSAGE = 'Passwords do not match.';

export interface UseShowPasswordErrorProps {
  doPasswordsMatch: boolean;
  confirmPassword?: string;
  isMinLengthValid?: boolean;
}

export const useShowPasswordError = ({
  doPasswordsMatch,
  confirmPassword,
  isMinLengthValid,
}: UseShowPasswordErrorProps) => {
  const [confirmIsDirty, setConfirmIsDirty] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    CONFIRM_PASSWORD_ERROR_MESSAGE,
  );

  useEffect(() => {
    setConfirmIsDirty(Boolean(confirmPassword?.length));
    setErrorMessage(CONFIRM_PASSWORD_ERROR_MESSAGE);
  }, [confirmPassword]);

  useEffect(() => {
    // If the password length is met on the first password, then validate that both passwords match
    setShowError(!!isMinLengthValid && !doPasswordsMatch && confirmIsDirty);
  }, [doPasswordsMatch, confirmIsDirty, isMinLengthValid]);

  const setSubmitError = useCallback((message: string) => {
    setErrorMessage(message);
    setShowError(true);
  }, []);

  return {
    showError,
    errorMessage,
    setSubmitError,
  };
};
