import React, { Component } from 'react';

import { DefaultTimeout } from '../../const';
import { ToastOptions } from '../../interface';
import Toast, { ToastType } from '../../models/toast';
import { Provider } from '../ToastContext/index';

interface ToastProviderState {
  toasts: Toast[];
}

const defaultOptions: ToastOptions = {
  timeout: 3000,
};

/* extra 1000 ms for letting the animation 
execute before deleting the array item. does not impact
what the user sees */
const animationDelay = 1000;

class ToastProvider extends Component<unknown, ToastProviderState> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      toasts: [],
    };
  }

  showToast = (
    message: string,
    type: ToastType = 'approved',
    options: ToastOptions = defaultOptions,
  ): void => {
    const { timeout = DefaultTimeout } = options;

    // create new toast instance
    const newToast = new Toast(Date.now().toString(), message, type, timeout);

    // update toast array state
    this.setState(({ toasts }) => ({ toasts: [...toasts, newToast] }));

    // set timeout to hide toasts after certain amount of time
    setTimeout(() => {
      this.deleteToast(newToast.id);
    }, timeout + animationDelay);
  };

  // deletes toast
  deleteToast = (id: string): void => {
    this.setState(({ toasts }) => ({
      toasts: toasts.filter((toast) => toast.id !== id),
    }));
  };

  render(): JSX.Element {
    const { toasts } = this.state;
    // @ts-ignore
    const { children } = this.props;
    const { showToast, deleteToast } = this;
    return (
      <Provider value={{ toasts, showToast, deleteToast }}>{children}</Provider>
    );
  }
}

export default ToastProvider;
