import { makeAutoObservable } from 'mobx';

import { ILegalDoc } from '../services/response';

export default class LegalDoc {
  constructor(public title: string = '', public body: string = '') {
    makeAutoObservable(this);
  }

  static fromRaw(raw: ILegalDoc) {
    return new LegalDoc(raw.legal_doc_title, raw.legal_doc_body);
  }
}
