import { computed, makeObservable } from 'mobx';

import { Store } from '@frontend-monorepo/cyolo-store';

import AuthProvider from '../../model/entity/auth-provider';
import AuthAPI from '../../services/api/auth';

const LocalAuthProviderId = 'local';

class AuthProviderStore extends Store<AuthProvider[]> {
  constructor() {
    super('auth_providers', AuthAPI.getAuthProviders, []);
    makeObservable(this, {
      redirectBasedAuthProviders: computed,
      submitBasedAuthProviders: computed,
      nonLocalSubmitBasedAuthProviders: computed,
    });
  }

  /**
   * are auth providers which you can redirect the
   * user to for perform auth transactions
   */
  get redirectBasedAuthProviders(): AuthProvider[] {
    return this.data.filter((provider) => !provider.submitForm);
  }

  /**
   * are auth providers which need to be sent the data in a POST
   * request explicitly to perform auth operations
   */
  get submitBasedAuthProviders(): AuthProvider[] {
    return this.data.filter((provider) => provider.submitForm);
  }

  /**
   * are submit based providers but not the default
   * local one (which is enabled by default most of the time)
   */
  get nonLocalSubmitBasedAuthProviders(): AuthProvider[] {
    return this.submitBasedAuthProviders.filter(
      (provider) => provider.id !== LocalAuthProviderId,
    );
  }
}

export default AuthProviderStore;
