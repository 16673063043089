import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import { MfaId } from '@frontend-monorepo/cyolo-auth';
import {
  CyTheme,
  Icon,
  IconID,
  Text,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';

const useStyles = createUseStyles((theme: CyTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '1 0 auto',
    minWidth: 420,
    minHeight: 180,
    padding: 36,
    gap: 8,
  },
  row: {
    display: 'flex',
    gap: 8,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
    width: '100%',
  },
  label: {
    color: theme.palette.primary.color,
    flex: 1,
    cursor: 'pointer',
  },
}));

interface MfaMethodsProps {
  username: string;
  userPhone: string | null;
  totpCode: string | null;
  mfaMethods: MfaId[];
  onMfaMethodClick: (method: MfaId, mobile: boolean) => void;
  isMobileOtpEnabled: boolean;
}

const MfaMethods: FC<MfaMethodsProps> = ({
  username,
  userPhone,
  totpCode,
  onMfaMethodClick,
  mfaMethods,
  isMobileOtpEnabled,
}) => {
  const theme = useThemeSafe();
  const styles = useStyles({ theme });

  const handleMethodClick = (method: MfaId, mobile: boolean) => {
    onMfaMethodClick(method, mobile);
  };

  const resolveIconId = (
    method: MfaId,
    mobileAuthenticator: boolean,
  ): IconID => {
    if (mobileAuthenticator) {
      return 'authenticator-auto-small';
    }

    switch (method) {
      case MfaId.SMS:
        return 'sms-small';
      case MfaId.TOTP:
        return 'authenticator-small';
      case MfaId.EMAIL:
        return 'email-small';
    }
  };

  const resolveLabel = (method: MfaId, mobileAuthenticator: boolean) => {
    if (mobileAuthenticator) {
      return 'Approve a request on Cyolo Mobile app';
    }

    switch (method) {
      case MfaId.SMS:
        return `Send an SMS message to ${userPhone}`;
      case MfaId.TOTP:
        return 'Use a verification code from authenticator';
      case MfaId.EMAIL:
        return 'Get an email verification code';
    }
  };

  const renderOption = (method: MfaId, mobileOTP: boolean) => {
    return (
      <div className={styles.row}>
        <Icon size={32} id={resolveIconId(method, mobileOTP)} />
        <Text
          key={method}
          className={styles.label}
          variant="body"
          onClick={() => handleMethodClick(method, mobileOTP)}
        >
          {resolveLabel(method, mobileOTP)}
        </Text>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={styles.root}>
        <Icon size={56} id="verification" />
        <Text variant="login-sub-title">Welcome {username}</Text>
        <Text variant="body">Select a method to verify your identity</Text>
        {isMobileOtpEnabled &&
          mfaMethods.includes(MfaId.TOTP) &&
          totpCode != '' &&
          renderOption(MfaId.TOTP, true)}

        {mfaMethods.map((id) => renderOption(id, false))}
      </div>
    );
  };

  return renderContent();
};

export default MfaMethods;
