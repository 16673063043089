/* eslint-disable simple-import-sort/imports */
/* Libs */
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'react-jss';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { Provider } from 'mobx-react';

import { ToastProvider } from '@frontend-monorepo/cyolo-toast';
import { cyTheme } from '@frontend-monorepo/cyolo-ui';
import { Stage, StageUtils as stageUtil } from '@frontend-monorepo/cyolo-utils';

/* Components */
import App from './components/App';
/* Stores */
import RootStore from './stores/root-store';
/* Config */
import config from './config.json';

/* Styles */
import '../../../shared/css/fonts.css';
import '@frontend-monorepo/cyolo-ui/lib/main.css';
import './index.scss';

//@ts-expect-error webpack build time global variable
console.log(`Running App version ${config.appVersion} (${COMMIT_HASH})`);

/* MobX */
const rootStore = new RootStore();

let root = (
  <StrictMode>
    <Provider rootStore={rootStore}>
      <ThemeProvider theme={cyTheme}>
        <ToastProvider>
          <App />
        </ToastProvider>
      </ThemeProvider>
    </Provider>
  </StrictMode>
);

interface Window {
  bugsnagEnabled?: boolean;
}

const { bugsnagEnabled = true } = window as Window;

if (bugsnagEnabled) {
  /* Bugsnag */
  Bugsnag.start({
    apiKey: config.bugSnag.apiKey,
    plugins: [new BugsnagPluginReact()],
    appVersion: config.appVersion, // static app version
    releaseStage: stageUtil.inferAppStage(), // try to get the current stage
    enabledReleaseStages: [Stage.Prod, Stage.Staging], // only send events in prod and staging
    onError: (event) => {
      //TODO: needs to be refactored
      // add company name to report
      if (rootStore.dataStores.companyInfoStore.state !== 'done') return;
      const { data } = rootStore.dataStores.companyInfoStore;
      event.addMetadata('company', {
        name: data.name,
      });
    },
  });

  // due to an invalid type error React cannot be passed as an arg to bugsnag
  const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

  root = (
    <ErrorBoundary
      FallbackComponent={({ error }) => {
        return (
          <div role="alert">
            <p>Something went wrong </p>
            <pre>{error.message}</pre>
          </div>
        );
      }}
    >
      {root}
    </ErrorBoundary>
  );
}

// @ts-ignore
createRoot(document.getElementById('root')).render(root);
