import React, { FC, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import { observer } from 'mobx-react';

import { useRootStore } from '../../../hooks/useRootStore';
import ResetPasswordScreen from '../ResetPassword/ResetPasswordScreen';

import FinishStep from './FinishStep';
import MethodStep from './MethodStep';
import Stepper from './Stepper';
import UsernameStep from './UsernameStep';
import VerificationStep from './VerificationStep';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
});

const RequestPasswordReset: FC = () => {
  const {
    stateStore: { requestPasswordResetState: store },
    uiStore,
  } = useRootStore();

  const styles = useStyles();

  // reset store when user moves from the page (back to /login, etc.)
  useEffect(() => {
    return () => {
      uiStore.setLoadingState('done');
      store.reset();
    };
  }, []);

  const renderStep = () => {
    switch (store.currentStep) {
      case 1:
        return <UsernameStep />;
      case 2:
        return <MethodStep />;
      case 3:
        return <VerificationStep />;
      case 4:
        return <ResetPasswordScreen username={store.username} />;
      case 5:
        return <FinishStep />;
    }
    return null;
  };

  if (uiStore.loadingStateOverride === 'error') {
    return <FinishStep type="error" />;
  }

  return (
    <div className={styles.root}>
      {store.showStepper && <Stepper />}
      {renderStep()}
    </div>
  );
};

export default observer(RequestPasswordReset);
