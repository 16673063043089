import { useEffect, useState } from 'react';

/**
 * useMinimalHeight return if window heigth is shortest to display poweredBy etc
 */
function useMinimalHeight(): boolean {
  const isWindowExists = typeof window === 'object';
  const minimalHeight = 760;

  const [isMinimal, setIsMinimal] = useState<boolean>(isHeightMinimal);
  useEffect(() => {
    if (!isWindowExists) {
      return;
    }

    /**
     * handleResize is handling window resize
     */
    function handleResize() {
      setIsMinimal(isHeightMinimal());
    }

    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  /**
   * calc height and compare to minimalHeight base
   */
  function isHeightMinimal(): boolean {
    if (!isWindowExists) {
      return false;
    }

    return window.innerHeight < minimalHeight;
  }

  return isMinimal;
}

export default useMinimalHeight;
