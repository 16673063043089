import { action, makeObservable, observable } from 'mobx';

import { ValidateType } from '../services/password-api';
import { PasswordPolicyValidState } from '../stores/state-store/update-and-reset-password-screen-state';

import {
  PasswordBlacklistRule,
  PasswordHistoryRule,
  PasswordRuleDescription,
  PasswordRuleLength,
  PasswordRuleSetRule,
} from './password-rule';

export enum ValidationRuleTitle {
  number = 'At least 1 number (0..9)',
  uppercase = 'At least 1 upper case letter (A-Z)',
  lowercase = 'At least 1 lower case letter (a-z)',
  minLength = 'At least 8 characters',
  maxLength = 'Maximum 64 characters',
  specialCharacter = 'At least 1 symbol (@#$!...)',
  previouslyUsed = 'Cannot be previously used',
  commonPasswords = 'Cannot be easily guessed',
  numberCharacter = 'At least 1 number (0-9)',
}

export const staticValidationRules: PasswordRuleDescription[] = [
  {
    code: 'number',
    type: 'regex',
    value: '(?=.*[0-9])',
  },
  {
    code: 'uppercase',
    type: 'regex',
    value: '(?=.*[A-Z])',
  },
  {
    code: 'lowercase',
    type: 'regex',
    value: '(?=.*[a-z])',
  },
  {
    code: 'minLength',
    type: 'number',
    value: '8',
  },
  {
    code: 'maxLength',
    type: 'number',
    value: '64',
  },
  {
    code: 'specialCharacter',
    type: 'regex',
    value: '(?=.*[!@#$%^&*])',
  },
];

export class PasswordValidator {
  rules: PasswordRuleSetRule[];
  minComplexity: number;

  constructor(
    validationRules: PasswordRuleDescription[],
    minComplexity: number = validationRules.length,
  ) {
    this.minComplexity = minComplexity;

    this.rules = validationRules.map((rule) => {
      return new PasswordRuleSetRule(rule);
    });
  }

  /**
   * checks if the password meets required number of rules
   */
  doesMeetAll(): boolean {
    const validatedList = this.rules.filter((rule) => rule.isValid === true);

    const minComplexity = this.minComplexity || this.rules.length;

    // return false if number of validated items doesn't meet the requirement
    return validatedList.length >= minComplexity;
  }

  /**
   * returns true if both new password and the confirm new password match as well as not empty
   */
  doPasswordsMatch(newPassword: string, confirmPassword: string) {
    return newPassword === confirmPassword && newPassword !== '';
  }

  reset(): void {
    this.rules.forEach((rule) => {
      rule.reset();
    });
  }
}

export class PolicyRulesValidator {
  minLengthRule: PasswordRuleLength;
  maxLengthRule: PasswordRuleLength;
  blacklistRule: PasswordBlacklistRule;
  historyRule: PasswordHistoryRule;
  state: PasswordPolicyValidState;

  constructor(minLength = 8, maxLength = 64) {
    this.state = new PasswordPolicyValidState();
    this.minLengthRule = new PasswordRuleLength(minLength, 'minLength');
    this.maxLengthRule = new PasswordRuleLength(maxLength, 'maxLength');
    this.blacklistRule = new PasswordBlacklistRule(this.state);
    this.historyRule = new PasswordHistoryRule(this.state);

    makeObservable(this, { state: observable, validateAsync: action.bound });
  }

  async validateAsync(
    password: string,
    type: ValidateType,
    onError: (err: unknown) => void,
  ) {
    this.blacklistRule.validatePassword(password, type, onError);
    this.historyRule.validatePassword(password, type, onError);
  }

  resetAsyncRules() {
    this.historyRule.reset();
    this.blacklistRule.reset();
  }
}
