import React, { CSSProperties, FC, useEffect, useState } from 'react';

import { reaction } from 'mobx';

import {
  CompanyLogo,
  CompanyLogoType,
  hasCompanyInfoStore,
} from '@frontend-monorepo/cyolo-company';

interface LogoProps {
  rootStore: hasCompanyInfoStore;
  type?: CompanyLogoType;
  style?: CSSProperties;
  forceAdminLogo?: boolean;
}

const key = 'logoKey';

const Logo: FC<LogoProps> = ({
  rootStore: { companyInfoStore },
  type,
  style = {},
  forceAdminLogo = false,
}) => {
  // support white-labeling, we dont show default icon until all data returned
  const [logoElement, setLogoElement] = useState(<div key={key} />);

  useEffect(() => {
    return reaction(
      () => companyInfoStore.state,
      (newState) => {
        if (newState !== 'done') {
          return;
        }

        const { icon, adminConsoleLogo } = companyInfoStore.data;
        // use adminConsoleLogo only when forceAdminLogo=true
        const customLogo = forceAdminLogo ? adminConsoleLogo : icon;

        if (Boolean(customLogo)) {
          setLogoElement(
            <img src={customLogo} alt="company logo" key={key} height={28} />,
          );
          return;
        }

        // default: cyolo company-logo
        setLogoElement(<CompanyLogo type={type} style={style} key={key} />);
      },
    );
  }, []);

  return logoElement;
};

export default Logo;
