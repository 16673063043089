import React, { FC, ReactElement, useCallback, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

import { when } from 'mobx';
import { observer } from 'mobx-react';

import { Card } from '@frontend-monorepo/cyolo-ui';
import { tryParsingBackendError } from '@frontend-monorepo/http-client';

import { useRootStore } from '../../../../hooks';
import { AuthProvider } from '../../../../model';
import { AppRoute } from '../../../../routes';
import pageRedirect from '../../../../services/page-redirect';
import { AppLoadingIndicator } from '../../../shared';
import IdpsForm from '../IdpForm/IdpsForm';

const IdpScreen: FC = () => {
  const { stateStore, uiStore, dataStores } = useRootStore();
  const { idpScreenState } = stateStore;

  const navigate = useNavigate();

  useEffect(() => {
    const checkIsExternalUserDispose = when(
      () => !dataStores.userIdentityStore.isExternalUser,
      () => {
        navigate('/' + AppRoute.Login, {
          replace: true,
        });
      },
    );

    const checkIsAuthenticatedUserDispose = when(
      () => !dataStores.authDataStore.data.isAnonymous,
      () => {
        pageRedirect.performPageRedirect();
      },
    );
    return () => {
      checkIsExternalUserDispose();
      checkIsAuthenticatedUserDispose();
    };
  }, []);

  const styles = useStyles();

  const selectIdp = async (provider: AuthProvider) => {
    try {
      await idpScreenState.selectProvider(provider);
    } catch (error) {
      uiStore.showToast(
        tryParsingBackendError(error, 'Login Failed'),
        'refused',
      );
    }
  };

  const onAnotherUserLogin = useCallback(async () => {
    await idpScreenState.performBackAction();
  }, [idpScreenState.performBackAction]);

  const renderCardContent = (): ReactElement | null => {
    switch (idpScreenState.idpState) {
      case 'in-work':
        return <AppLoadingIndicator />;
      case 'idle':
        return (
          <IdpsForm
            username={dataStores.userIdentityStore.data.username}
            idps={dataStores.userIdentityStore.externalUserProviders}
            onProviderClick={selectIdp}
            onBack={onAnotherUserLogin}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.loginViewRoot}>
      <div className={styles.cardContainer}>
        <Card className={styles.card}>
          <div className={styles.cardContent}>{renderCardContent()}</div>
        </Card>
      </div>
    </div>
  );
};

export default observer(IdpScreen);

const useStyles = createUseStyles({
  loginViewRoot: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cardContainer: {
    width: 440,
    flex: '1 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    width: 440,
    padding: '80px 0 !important',
  },
});
