import {
  HoldupRedirector,
  LocationDataLoader,
  QueryParamsErrorHandler,
} from '../reactions';

import DataStoreContainer from './data/data';
import EnrollmentPhaseSkipStore from './enrollment-phase-skip-store';
import LocationStore from './location-store';
import StateStore from './state-store';
import UiStore from './ui-store';

export interface RootStoreOptions {
  enableLocationBasedLoader: boolean;
}

// RootStore holds all different kinds of stores
// composing the entire app state, data, and different
// computed reactions and side effects
class RootStore {
  /* Local Data Stores */
  enrollmentPhaseSkipStore: EnrollmentPhaseSkipStore;

  /* Stores */
  public stateStore: StateStore;

  public uiStore: UiStore;

  locationStore: LocationStore;

  /* Store Based Reactions */
  locationDataLoader?: LocationDataLoader;

  queryParamsErrorHandler: QueryParamsErrorHandler;

  holdupRedirector: HoldupRedirector;

  constructor(
    options: RootStoreOptions = { enableLocationBasedLoader: true },
    public dataStores: DataStoreContainer = new DataStoreContainer(),
  ) {
    // internal stores
    this.locationStore = new LocationStore();
    this.enrollmentPhaseSkipStore = new EnrollmentPhaseSkipStore();
    this.uiStore = new UiStore(this.dataStores.companyInfoStore);
    this.stateStore = new StateStore(
      dataStores,
      this.locationStore,
      this.uiStore,
    );

    // reactions
    if (options.enableLocationBasedLoader) {
      this.locationDataLoader = new LocationDataLoader(
        this.locationStore,
        dataStores,
      );
    }

    this.queryParamsErrorHandler = new QueryParamsErrorHandler(
      this.uiStore,
      this.locationStore,
    );
    this.holdupRedirector = new HoldupRedirector(
      this.locationStore,
      dataStores,
    );
  }
}
export default RootStore;
