import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import { CyTheme, Text, useThemeSafe } from '@frontend-monorepo/cyolo-ui';

import VSign from '../../../../../assets/v-sign.svg';

interface StyleProps {
  step: Step;
}

const useStyles = createUseStyles((theme: CyTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
    zIndex: 1,
    flex: 0.9,
  },
  circle: {
    display: 'flex',
    height: 40,
    width: 40,
    border: `1px solid ${theme.palette.primary.color}`,
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 3,
    backgroundColor({ step }: StyleProps) {
      switch (step) {
        case 'done':
          return theme.palette.background.color;
        case 'current':
          return theme.palette.primary.color;
        case 'future':
          return theme.palette.white.color;
      }
    },
  },
  step: {
    color({ step }: StyleProps) {
      if (step === 'current') return theme.palette.white.color;
      return theme.palette.primary.color;
    },
  },
  description: {
    textAlign: 'center',
  },
}));

export type Step = 'done' | 'current' | 'future';

interface CircleProps {
  step: Step;
  number: number;
  description: string;
}

const Circle: FC<CircleProps> = ({ step, number, description }) => {
  const theme = useThemeSafe();
  const styles = useStyles({ theme, step: step });

  return (
    <div className={styles.root}>
      <div className={styles.circle}>
        {step === 'done' ? (
          <img src={VSign} alt="v-sign" />
        ) : (
          <Text variant="body" className={styles.step}>
            {number}
          </Text>
        )}
      </div>
      <Text className={styles.description} variant="body">
        {description}
      </Text>
    </div>
  );
};

export default Circle;
