import React, { FC, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import { Logo } from '@frontend-monorepo/cyolo-shell';
import { ActionIconProps, MinimalNavBar } from '@frontend-monorepo/cyolo-ui';

import { useRootStore } from '../../../hooks';
import pageRedirect from '../../../services/page-redirect';

import './NavBar.scss';

// TODO: refactor out of here
const LogoutButton: ActionIconProps = {
  text: 'Logout',
  onClick: (): void => {
    pageRedirect.performPageRedirect('/cyolo/v1/logout');
  },
};

// NavBar is the upper screen nav bar stylized component
const NavBar: FC = () => {
  const {
    dataStores: { companyInfoStore, authDataStore: authStore, userStore },
  } = useRootStore();

  const [navTitle, setNavTitle] = useState('');
  useEffect(() => {
    if (companyInfoStore.state !== 'done') {
      setNavTitle('');
      return;
    }

    setNavTitle(companyInfoStore.data.headerPlaceHolder);
  }, [companyInfoStore.state]);

  const userDropDownSections = useMemo(() => {
    const buttons: ActionIconProps[] = [];

    if (authStore.data?.authenticated) {
      buttons.push(LogoutButton);
    }

    return [buttons];
  }, [authStore.data?.authenticated]);

  return (
    <div className="NavBar-root">
      <MinimalNavBar
        variant="user-nav"
        logo={<Logo rootStore={{ companyInfoStore }} type="white" />}
        title={navTitle}
        userName={userStore.data?.name}
        firstName={userStore.data?.firstName}
        lastName={userStore.data?.lastName}
        userEmail={userStore.data?.email}
        userDropdownSections={userDropDownSections}
      />
    </div>
  );
};

export default observer(NavBar);
