import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import { Image, Text, useThemeSafe } from '@frontend-monorepo/cyolo-ui';

import DotSign from '../../../../../assets/dot-grey.svg';
import LoadingSign from '../../../../../assets/loading-spinner.svg';
import VSign from '../../../../../assets/v-sign-blue.svg';

interface PasswordRequirementFieldProps {
  text: string;
  valid?: boolean;
  isPending?: boolean;
}

const PasswordRequirementField: FC<
  React.PropsWithChildren<PasswordRequirementFieldProps>
> = ({ text, valid = false, isPending = false }) => {
  const theme = useThemeSafe();
  const styles = useStyles();

  return (
    <div className={styles.requirementsField}>
      {!isPending ? (
        <Image src={valid ? VSign : DotSign} alt="indicator" size={10} />
      ) : (
        <Image
          src={LoadingSign}
          alt="indicator"
          size={10}
          className={styles.loadingImg}
        />
      )}

      <Text variant="body" color={theme.palette.grey.color}>
        {text}
      </Text>
    </div>
  );
};

export default PasswordRequirementField;

const useStyles = createUseStyles({
  requirementsField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    columnGap: 8,
  },
  loadingImg: {
    animation: '$loading 0.7s infinite linear',
  },
  '@keyframes loading': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
});
