import React, { CSSProperties, FC } from 'react';

import { Image } from '@frontend-monorepo/cyolo-ui';

import CompanyLogoSvg from '../../../assets/company-logo.svg';
import BlueCompanyLogoSvg from '../../../assets/company-logo-blue.svg';
import WhiteCompanyLogoSvg from '../../../assets/company-logo-white.svg';

import styles from './CompanyLogo.styles';

export type CompanyLogoType = 'regular' | 'white' | 'blue';

interface CompanyLogoProps {
  type?: CompanyLogoType;
  style?: CSSProperties;
}

const CompanyLogo: FC<CompanyLogoProps> = ({
  type = 'regular',
  style = {},
}) => {
  let svg: string;
  switch (type) {
    case 'regular':
      svg = CompanyLogoSvg;
      break;
    case 'white':
      svg = WhiteCompanyLogoSvg;
      break;
    case 'blue':
      svg = BlueCompanyLogoSvg;
      break;
    default:
      svg = CompanyLogoSvg;
      break;
  }

  return (
    <Image
      alt="an application icon"
      style={{ ...styles.appIcon, ...style }}
      src={svg}
    />
  );
};

export default CompanyLogo;
