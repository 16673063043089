import React, { FC } from 'react';

import { observer } from 'mobx-react';

import { CompanyLogo } from '@frontend-monorepo/cyolo-company';
import { Divider, Text } from '@frontend-monorepo/cyolo-ui';

import colors from '../../../colors';
import { useRootStore } from '../../../hooks';

import './PoweredBy.scss';

interface PoweredByProps {
  width?: number;
}

// PoweredBy shows the signature Cyolo logo and powered by texts
const PoweredBy: FC<PoweredByProps> = ({ width = 496 }) => {
  const { uiStore } = useRootStore();

  return (
    <div className="PoweredBy-root">
      <Divider orientation="horizontal" length={width} color={colors.grey} />
      <span className="PoweredBy-texts">
        <Text style={{ marginRight: 5, marginTop: 1 }} variant="body">
          Powered by
        </Text>
        <CompanyLogo type="blue" />
        {!uiStore.isMobile && (
          <Text
            style={{ marginLeft: 8, marginTop: 2, opacity: 0.5 }}
            variant="body"
          >
            Your secure remote work environment
          </Text>
        )}
      </span>
    </div>
  );
};

export default observer(PoweredBy);
