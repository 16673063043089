import { makeObservable, observable } from 'mobx';

import { MfaMethod } from '@frontend-monorepo/cyolo-auth';
import { ResourceUrl } from '@frontend-monorepo/cyolo-company';

import { ProviderResponse } from '../../services/api/auth/response';
import { PasswordPolicy } from '../password-policy';

class AuthProvider {
  /**
   * AuthProvider describes a first/third party which provides auth method
   * which will be shown to the end user in the login screen
   */
  id: string;
  submitForm: boolean;
  icon: ResourceUrl;
  url: string;
  name: string;
  allowedMfaMethods: MfaMethod[];
  passwordPolicy: PasswordPolicy | null;
  visible: boolean;

  constructor(
    id: string,
    submitForm: boolean,
    icon: string,
    url: string,
    name: string,
    allowedMfaMethods: MfaMethod[] = [],
    passwordPolicy: PasswordPolicy | null,
    visible: boolean,
  ) {
    makeObservable(this, {
      id: observable,
      submitForm: observable,
      icon: observable,
      url: observable,
      name: observable,
      allowedMfaMethods: observable,
      passwordPolicy: observable,
      visible: observable,
    });

    this.id = id;
    this.submitForm = submitForm;
    this.icon = icon;
    this.url = url;
    this.name = name;
    this.allowedMfaMethods = allowedMfaMethods;
    this.passwordPolicy = passwordPolicy;
    this.visible = visible;
  }

  static fromRaw(providerResponse: ProviderResponse) {
    const {
      id,
      icon,
      name,
      allowed_mfa_methods,
      password_policy,
      submitForm,
      url,
      visible,
    } = providerResponse;

    return new AuthProvider(
      String(id),
      submitForm,
      icon,
      url,
      name,
      allowed_mfa_methods.map(MfaMethod.fromRaw),
      PasswordPolicy.fromRaw(password_policy),
      visible,
    );
  }
}

export default AuthProvider;
