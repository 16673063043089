import { makeAutoObservable, runInAction } from 'mobx';

import API from '../services/api/api';
import { ApprovalState } from '../services/api/response';

export const approver_key = 'approval_approver';
export const reason_key = 'approval_reason';

class ApprovalStore {
  state: ApprovalState;
  expirationTime: string;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.state = ApprovalState.prePending;
    this.expirationTime = '';
  }

  // approval status starts as prePending, this function will set it to passPrepending and then

  async fetch(): Promise<void> {
    // parse out approval id
    const approvalId = new URLSearchParams(location.search).get('id');

    // verify that approval id is not null/undefined
    if (!approvalId) {
      return;
    }

    const resp = await API.fetchApprovalStatus(approvalId);

    runInAction(() => {
      sessionStorage.setItem(approver_key, resp.approverName);
      sessionStorage.setItem(reason_key, resp.approverReason);
      this.state = resp.approvalState as ApprovalState;
      this.expirationTime = resp.expirationTime;
    });
  }

  // the status polling from server will start, if no approval found it gets rejected and don't start polling
  async commitPrependingApproval() {
    // we can only commit if were in pre pending mode
    if (this.state !== ApprovalState.prePending) return;

    // parse out approval id
    const approvalId = new URLSearchParams(location.search).get('id');
    // verify that approval id is not null/undefined
    if (!approvalId) return;
    const res = await API.commitPrependingApproval(approvalId);
    runInAction(() => {
      this.state = res ? ApprovalState.passPrepending : ApprovalState.rejected;
    });
  }
}
export default ApprovalStore;
