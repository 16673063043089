import { makeAutoObservable, reaction, runInAction, when } from 'mobx';

import AuthAPI from '../../../services/api/auth';
import DataStoreContainer from '../../data/data';

type ComputerUpdateState = 'idle' | 'in-work' | 'error';
const DEFAULT_PERSONAL_DESKTOP_MSG = 'Enter desktop name';

class PersonalDesktopViewState {
  input: string;

  computerUpdateState: ComputerUpdateState;

  constructor(private dataStores: DataStoreContainer) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.input = '';
    this.computerUpdateState = 'idle';

    // after change in the update state fetch new user data
    reaction(
      () => this.computerUpdateState !== 'in-work',
      () => {
        // fetch new user data
        this.dataStores.userStore.fetch();
      },
    );

    // populate remote desktop of its already in user data
    when(
      () => this.dataStores.userStore.state === 'done',
      () => {
        const { userStore } = this.dataStores;
        if (!userStore.data) return;
        if (userStore.data.personalDesktop === '') return;
        this.input = userStore.data.personalDesktop;
      },
    );
  }

  /**
   * personalDesktopMessage retrieves the custom personal desktop enrollment message
   * from the store if exists.
   */
  get personalDesktopMessage(): string {
    const store = this.dataStores.companyInfoStore;

    if (store.state !== 'done') {
      return DEFAULT_PERSONAL_DESKTOP_MSG;
    }

    if (store.data.personalDesktopMessage === '') {
      return DEFAULT_PERSONAL_DESKTOP_MSG;
    }

    return store.data.personalDesktopMessage;
  }

  get isFinished(): boolean {
    if (!this.dataStores.userStore.data) return false;
    return this.dataStores.userStore.data.personalDesktop !== '';
  }

  private minInputChars = 3;

  get canPressNext(): boolean {
    return this.input.length >= this.minInputChars;
  }

  resetUpdateState() {
    this.computerUpdateState = 'idle';
  }

  async savePersonalComputerInformation() {
    if (!this.canPressNext) return;

    this.computerUpdateState = 'idle';
    try {
      this.computerUpdateState = 'in-work';
      await AuthAPI.putUserComputerName(this.input);
      runInAction(() => {
        this.computerUpdateState = 'idle';
      });
    } catch (e) {
      runInAction(() => {
        this.computerUpdateState = 'error';
      });
    }
  }
}

export default PersonalDesktopViewState;
