import React, { FC, lazy, Suspense, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { autorun } from 'mobx';
import { observer } from 'mobx-react';

import { CompanyInfoStore } from '@frontend-monorepo/cyolo-company';
import { Toaster } from '@frontend-monorepo/cyolo-toast';
import { LoadingIndicator, LoadingOverlay } from '@frontend-monorepo/cyolo-ui';

import { useRootStore } from '../../hooks';
import useMinimalHeight from '../../hooks/useMinimalHeight';
import { AppRoute, splat } from '../../routes';
import UiStore from '../../stores/ui-store';
import {
  AgentLogoutScreen,
  IdpScreen,
  LoginScreen,
  MfaScreen,
  PasswordScreen,
  RedirectScreen,
  RequestPasswordResetScreen,
  UpdatePasswordScreen,
} from '../Screens';
import InvitedUser from '../Screens/InvitedUser/InvitedUser';
import {
  AppBackground,
  HistoryMonitor,
  NavBar,
  PoweredBy,
  ToastAdapter,
} from '../shared';

import '@fontsource/heebo/100.css';
import '@fontsource/heebo/200.css';
import '@fontsource/heebo/300.css';
import '@fontsource/heebo/400.css';
import '@fontsource/heebo/500.css';
import '@fontsource/heebo/600.css';
import '@fontsource/heebo/700.css';
import '@fontsource/heebo/800.css';
import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';

const EnrollRoutes = lazy(
  () => import('../Screens/Enroll/Screen/EnrollScreen'),
);
const AwaitingApprovalScreen = lazy(() => import('../Screens/Waiting'));
const SupervisorApprovalScreen = lazy(
  () => import('../Screens/SupervisorApproval'),
);
const WelcomeScreen = lazy(() => import('../Screens/Welcome'));
const NotifyIdpScreen = lazy(() => import('../Screens/NotifyIdp'));
const ApproverScreen = lazy(() => import('../Screens/Approver'));

interface StyleProps {
  isMinimalHeight: boolean;
}

const headerHeight = 70;
const useStyle = createUseStyles({
  appRoot: {
    position: 'absolute',
    top: headerHeight,
    bottom: ({ isMinimalHeight }: StyleProps) => (isMinimalHeight ? 0 : 96),
    left: 0,
    right: 0,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  poweredByContainer: {
    position: 'fixed',
    bottom: 0,
    paddingBottom: 32,
  },
});

/**
 * fetches initial stores data
 * @param uiStore - ui store for toasts
 * @param companyInfoStore
 */
const useFetchInitialData = (
  uiStore: UiStore,
  companyInfoStore: CompanyInfoStore,
) => {
  useAsyncEffect(async () => {
    try {
      await companyInfoStore.fetch();
    } catch (error) {
      uiStore.showToast('failed fetching initial data', 'refused');
    }
  }, []);
};

console.log('APP_MODE: ', process?.env?.NODE_ENV);

const App: FC = () => {
  const {
    uiStore,
    dataStores: { companyInfoStore },
  } = useRootStore();
  useFetchInitialData(uiStore, companyInfoStore);

  useEffect(() => {
    return autorun(() => {
      document.title = uiStore.appTitle;
    });
  }, []);

  const isMinimalHeight = useMinimalHeight();
  const style = useStyle({ isMinimalHeight });

  return (
    <>
      <Router>
        <HistoryMonitor />
        <AppBackground />
        <NavBar />
        <Toaster />
        <ToastAdapter />
        <div className={style.appRoot}>
          <Suspense fallback={<LoadingIndicator color="blue" />}>
            <Routes>
              <Route
                path="*"
                element={<Navigate to={AppRoute.Redirect} replace />}
              />
              <Route
                key="redirect"
                path={AppRoute.Redirect}
                element={<RedirectScreen />}
              />
              <Route
                key="login"
                path={AppRoute.Login}
                element={<LoginScreen />}
              />
              <Route key="idps" path={AppRoute.IDPs} element={<IdpScreen />} />
              <Route
                key="setPassword"
                path={AppRoute.Password}
                element={<PasswordScreen />}
              />
              <Route
                key="welcome"
                path={AppRoute.Welcome}
                element={<WelcomeScreen />}
              />
              <Route
                key="notifyIdp"
                path={AppRoute.NotifyIdp}
                element={<NotifyIdpScreen />}
              />
              <Route key="mfa" path={AppRoute.Mfa} element={<MfaScreen />} />
              <Route
                key="enroll"
                path={AppRoute.Enroll + splat}
                element={<EnrollRoutes />}
              />
              <Route
                key="supervisor"
                path={AppRoute.Supervisor}
                element={<AwaitingApprovalScreen />}
              />
              <Route
                key="disabled"
                path={AppRoute.Disabled}
                element={<AwaitingApprovalScreen />}
              />
              <Route
                key="unauthorized"
                path={AppRoute.NoPermission}
                element={<AwaitingApprovalScreen />}
              />
              <Route key="approvals" path={AppRoute.ApprovalWaiting + splat}>
                <Route index element={<AwaitingApprovalScreen />} />
                <Route path={':state'} element={<AwaitingApprovalScreen />} />
              </Route>

              <Route
                key="updatePassword"
                path={AppRoute.UpdatePassword}
                element={<UpdatePasswordScreen />}
              />
              <Route
                key="resetPassword"
                path={AppRoute.RequestResetPassword}
                element={<RequestPasswordResetScreen />}
              />
              <Route
                key="approverPolicy"
                path={AppRoute.ApproverPolicy}
                element={<ApproverScreen />}
              />
              <Route
                key="approver"
                path={AppRoute.Approver + splat}
                element={<ApproverScreen />}
              />
              <Route
                key="supervisorApproval"
                path={AppRoute.SupervisorApproval}
                element={<SupervisorApprovalScreen />}
              />
              <Route
                key="inviteUser"
                path={`${AppRoute.InviteUser}/:token`}
                element={<InvitedUser />}
              />
              <Route
                key="agentLogout"
                path={AppRoute.AgentLogout}
                element={<AgentLogoutScreen />}
              />
            </Routes>
          </Suspense>
          {!isMinimalHeight && (
            <div className={style.poweredByContainer}>
              <PoweredBy width={440} />
            </div>
          )}
        </div>
      </Router>
      {uiStore.showLoadingOverlay && <LoadingOverlay />}
    </>
  );
};

export default observer(App);
