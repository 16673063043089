import { makeAutoObservable } from 'mobx';

import { Identifiable } from '@frontend-monorepo/cyolo-utils';

import { MfaId } from '../enum';
import { MfaMethodResponse } from '../services/api/mfa';

export class MfaMethod implements Identifiable<MfaId> {
  id: MfaId;
  name: string;

  constructor(id: MfaId, name: string) {
    this.id = id;
    this.name = name;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  static fromRaw(raw: MfaMethodResponse): MfaMethod {
    return new MfaMethod(raw.id, raw.name);
  }

  clone(): MfaMethod {
    return new MfaMethod(this.id, this.name);
  }
}
