import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import { observer } from 'mobx-react';

import { ValidationRuleTitle } from '../../../../model/password-validator';
import { UpdatePasswordScreenState } from '../../../../stores/state-store/update-and-reset-password-screen-state';
import RequirementsSection from '../../../shared/RequirementsSection';
import PasswordRequirementField from '../../../shared/RequirementsSection/PasswordRequirementField';

import PasswordsSection from './PasswordsSection/PasswordsSection';

import './Sections.css';

interface SectionsProps {
  store: UpdatePasswordScreenState;
}

/**
 * Cards is a component that wraps the passwords card and the requirements.
 * @constructor
 */
const Sections: FC<SectionsProps> = ({ store }) => {
  const styles = useStyles();

  const validatorRulesTitle = `${
    store?.policyValidator ? 'And' : 'Password must'
  }  meet ${store.validator?.minComplexity} of the following ${
    store.validator?.rules.length
  } types:`;

  return (
    <div className={styles.sections}>
      <PasswordsSection />
      <div className={styles.rightPanel}>
        {store.policyValidator && (
          <RequirementsSection>
            <RequirementsSection.Title>
              Password must meet the following:
            </RequirementsSection.Title>
            <PasswordRequirementField
              text={`At least ${store.policyValidator.minLengthRule.lengthNum} characters`}
              isPending={store.policyValidator.minLengthRule.isPending}
              valid={store.policyValidator.minLengthRule.isValid}
            />
            {store.enableBlackList && (
              <PasswordRequirementField
                text={ValidationRuleTitle.commonPasswords}
                isPending={store.policyValidator.blacklistRule.isPending}
                valid={store.policyValidator.blacklistRule.isValid}
              />
            )}
          </RequirementsSection>
        )}
        <RequirementsSection>
          <RequirementsSection.Title>
            {validatorRulesTitle}
          </RequirementsSection.Title>
          {store.validator?.rules.map((rule) => (
            <PasswordRequirementField
              key={rule.code}
              text={ValidationRuleTitle[rule.code]}
              valid={rule.isValid}
            />
          ))}
        </RequirementsSection>
      </div>
    </div>
  );
};

export default observer(Sections);

const useStyles = createUseStyles({
  sections: {
    display: 'flex',
    columnGap: 40,
  },
  rightPanel: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
  },
});
