import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

import { observer } from 'mobx-react';

import {
  Button,
  Card,
  CyTheme,
  Divider,
  Text,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';
import { tryParsingBackendError } from '@frontend-monorepo/http-client';

import { useRootStore } from '../../../hooks/useRootStore';
import { AppRoute } from '../../../routes';
import DigitsInput from '../../shared/DigitsInput';

const useStyles = createUseStyles((theme: CyTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    gap: 30,
    width: 350,
    padding: '40 50',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    textAlign: 'center',
    alignSelf: 'center',
    width: 350,
  },
  otp: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    textAlign: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    width: 140,
  },
  resendSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 4,
    height: 48,
    marginTop: '16px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '20',

    '& a': {
      color: theme.palette.primary.color,
      fontFamily: 'sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      textAlign: 'center',
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
}));

const VerificationStep: FC = () => {
  const {
    stateStore: { requestPasswordResetState: store },
    uiStore,
  } = useRootStore();

  const theme = useThemeSafe();
  const styles = useStyles({ theme });
  const retryTimer = store.requestStore.formattedtimeUntilSmsCanBeSentAgain;

  const onCompletion = async (code: string): Promise<boolean> => {
    try {
      uiStore.setLoadingState('pending');
      await store.submitOtp(code);
      return true;
    } catch (e) {
      uiStore.showToast(tryParsingBackendError(e, 'Incorrect code'), 'refused');
      return false;
    } finally {
      uiStore.setLoadingState('done');
    }
  };

  const onResend = async () => {
    try {
      uiStore.setLoadingState('pending');
      await store.submitMethod();
    } catch (e) {
      uiStore.showToast(
        tryParsingBackendError(e, 'Failed to resend code'),
        'refused',
      );
    } finally {
      uiStore.setLoadingState('done');
    }
  };

  return (
    <Card className={styles.root}>
      <div className={styles.header}>
        <Text variant="login-sub-title">
          Check your {store.selectedMfaMethod}
        </Text>
        <Text variant="body">A verification code has been sent</Text>
      </div>
      <div className={styles.otp}>
        <Text variant="body">Enter Code</Text>
        <DigitsInput
          key="digits-input"
          numberOfKeys={6}
          active={true}
          onCompletion={(code) => {
            return onCompletion(code);
          }}
        />
        {
          <div className={styles.resendSection}>
            {retryTimer && <Text variant="body">{retryTimer}</Text>}
            <Button
              style={{ width: 160, height: 26 }}
              variant="primary"
              onClick={onResend}
              disabled={!store.canResendCode}
            >
              Resend {store.selectedMfaMethod}
            </Button>
          </div>
        }
      </div>
      <div className={styles.header}>
        <Divider orientation="horizontal" thickness={1} />
        <Text variant="body" color={theme.palette.grey.color}>
          To continue, please enter below the 6-digit code you received. If you
          have not received it, please go back and try another way of resetting,
          or contact your administrator.
        </Text>
      </div>
      <div className={styles.footer}>
        <Link to={AppRoute.Login}> Cancel </Link>
        <a onClick={store.backStep}> Back </a>
      </div>
    </Card>
  );
};

export default observer(VerificationStep);
