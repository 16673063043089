import { HttpStatusCode } from '@frontend-monorepo/cyolo-store';
import axios from '@frontend-monorepo/http-client';

import Endpoints from './endpoints';
import {
  IMultipleUsersResponse,
  IUpdatableUserObject,
  IUserObject,
} from './response';

const getMultipleUsers = async (): Promise<IMultipleUsersResponse> => {
  const { data } = await axios.get<IMultipleUsersResponse>(Endpoints.GetUsers);
  return data;
};

const createUser = async (raw: IUpdatableUserObject) => {
  await axios.put(Endpoints.CreateUser, raw);
};

const createUsers = async (raws: IUpdatableUserObject[]) => {
  await axios.put(Endpoints.CreatUserBulk, raws);
};

const updateUser = async (userId: string, raw: IUpdatableUserObject) => {
  await axios.post(Endpoints.UpdateUser(userId), raw);
};

const deleteUser = async (userId: string) => {
  await axios.delete(Endpoints.DeleteUser(userId));
};

const deleteMultipleUsers = async (userIds: string[]) => {
  await axios.delete(Endpoints.DeleteUsersBulk, { data: userIds });
};

const getUserData = async (): Promise<IUserObject> => {
  const { data } = await axios.get<IUserObject>(Endpoints.GetUserData, {
    validateStatus: (status) => {
      // if we get a 403 we ignore it explicitly
      return (
        status < HttpStatusCode.ClientErrorCodeStart ||
        status === HttpStatusCode.UserAnon
      );
    },
  });

  return data;
};

const resetEnrollmentBulk = async (userIds: string[]) => {
  await axios.post(Endpoints.ResetEnrollmentBulk, userIds);
};

export default {
  getUserData,
  getMultipleUsers,
  createUser,
  createUsers,
  updateUser,
  deleteUser,
  deleteMultipleUsers,
  resetEnrollmentBulk,
};
