import React, { FC, useEffect } from 'react';

import { when } from 'mobx';
import { observer } from 'mobx-react';

import { Image, LoadingOverlay, Text } from '@frontend-monorepo/cyolo-ui';
import { tryParsingBackendError } from '@frontend-monorepo/http-client';

import LockIcon from '../../../../assets/password-lock.svg';
import { useRootStore } from '../../../hooks';
import { ValidateType } from '../../../services/password-api';
import { useResetScreenReaction } from '../../hooks';
import ResetPasswordWrapper from '../../shared/ResetPasswordWrapper';

import { default as Sections } from './Sections';

const MUST_CHANGE_PASSWORD_TITLE = 'You are required to changed your password';

/**
 * UpdatePasswordScreen is a screen for updating user's passwords.
 * @param rootStore
 * @constructor
 */
const UpdatePasswordScreen: FC = () => {
  const {
    uiStore,
    stateStore: { updatePasswordScreenState },
    dataStores: { userStore, userIdentityStore },
  } = useRootStore();

  const mustChangePassword = userStore?.data?.mustChangePassword;

  const bootstrap = async () => {
    try {
      await updatePasswordScreenState.bootstrap();
    } catch (e) {
      uiStore.showToast(
        tryParsingBackendError(
          e,
          'Failed to retrieve days left for current password',
        ),
        'refused',
      );
    }
  };

  useResetScreenReaction(
    updatePasswordScreenState,
    uiStore,
    ValidateType.UpdatePassword,
  );

  useEffect(() => {
    return when(
      () => userIdentityStore.state === 'done',
      async () => {
        try {
          if (!userIdentityStore.data.passwordProvider) {
            return;
          }

          if (userIdentityStore.data.passwordProvider.passwordPolicy) {
            await updatePasswordScreenState.setPasswordPolicy(
              userIdentityStore.data.passwordProvider.passwordPolicy,
            );
          } else {
            await updatePasswordScreenState.setupValidator();
          }
        } catch (err) {
          uiStore.showToast(
            tryParsingBackendError(err, 'Something went wrong'),
            'refused',
          );
        }
      },
    );
  }, []);

  useEffect(() => {
    bootstrap();
  }, []);

  if (updatePasswordScreenState.validator === null) {
    return <LoadingOverlay />;
  }

  return (
    <ResetPasswordWrapper>
      <ResetPasswordWrapper.Title>
        <Image src={LockIcon} size={54} alt="change-password-lock" />
        <Text variant="login-sub-title">Change Password</Text>
        <Text variant="body">
          {mustChangePassword
            ? MUST_CHANGE_PASSWORD_TITLE
            : updatePasswordScreenState.expiryText}
        </Text>
      </ResetPasswordWrapper.Title>
      <ResetPasswordWrapper.Body>
        <Sections store={updatePasswordScreenState} />
      </ResetPasswordWrapper.Body>
    </ResetPasswordWrapper>
  );
};

export default observer(UpdatePasswordScreen);
