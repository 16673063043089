import { Store } from '@frontend-monorepo/cyolo-store';

import { CompanyInfo } from '../../model';
import { CompanyAPI } from '../../services';

import { ICompanyInfoStore } from './interface';

export class CompanyInfoStore
  extends Store<CompanyInfo>
  implements ICompanyInfoStore
{
  constructor() {
    super(
      'company_info',
      async () => {
        return CompanyInfo.fromRaw(await CompanyAPI.getCompanyInfo());
      },
      new CompanyInfo(),
    );
  }
}
