import React, { ChangeEvent, FC } from 'react';
import { createUseStyles } from 'react-jss';

import { observer } from 'mobx-react';

import { Button, CyTheme, useThemeSafe } from '@frontend-monorepo/cyolo-ui';
import { tryParsingBackendError } from '@frontend-monorepo/http-client';

import { useRootStore } from '../../../../../hooks/useRootStore';
import { useShowPasswordError } from '../../../../hooks';
import PasswordAndConfirm from '../../../../shared/PasswordAndConfirm';
import { PasswordType } from '../../../UpdatePassword/enum';

const PasswordsSection: FC = () => {
  const {
    uiStore,
    stateStore: { invitedUserScreenState },
  } = useRootStore();

  const theme = useThemeSafe();

  const { showError, errorMessage, setSubmitError } = useShowPasswordError({
    confirmPassword: invitedUserScreenState.newPasswordState.confirmPassword,
    doPasswordsMatch: invitedUserScreenState.doPasswordsMatch,
    isMinLengthValid:
      invitedUserScreenState.policyValidator?.minLengthRule.isValid,
  });

  const styles = useStyles({ theme });

  const handlePasswordChange = (
    type: PasswordType,
    e: ChangeEvent<HTMLInputElement>,
  ): void => {
    switch (type) {
      case PasswordType.New:
        invitedUserScreenState.newPasswordState.setNewPassword(e.target.value);
        return;
      case PasswordType.Confirm:
        invitedUserScreenState.newPasswordState.setConfirmPassword(
          e.target.value,
        );
        return;
    }
  };

  const handleReset = async () => {
    if (!invitedUserScreenState.isValid) {
      return;
    }

    try {
      uiStore.setLoadingState('pending');
      await invitedUserScreenState.resetPassword();
      invitedUserScreenState.nextStep();
    } catch (error) {
      setSubmitError(
        tryParsingBackendError(error, 'Failed to update password'),
      );
    } finally {
      uiStore.setLoadingState('done');
    }
  };

  return (
    <div className={styles.wrapper}>
      <PasswordAndConfirm
        newPasswordValue={invitedUserScreenState.newPasswordState.newPassword}
        confirmPasswordValue={
          invitedUserScreenState.newPasswordState.confirmPassword
        }
        handleNewPasswordChange={(e) =>
          handlePasswordChange(PasswordType.New, e)
        }
        handleConfirmPasswordChange={(e) =>
          handlePasswordChange(PasswordType.Confirm, e)
        }
        errorMessage={errorMessage}
        showError={showError}
      />
      <div className={styles.buttonWrap}>
        <Button
          variant="primary"
          onClick={handleReset}
          disabled={!invitedUserScreenState.isValid}
        >
          Set
        </Button>
      </div>
    </div>
  );
};

export default observer(PasswordsSection);

const useStyles = createUseStyles((theme: CyTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
  },
  buttonWrap: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
  },
}));
