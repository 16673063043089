import { AxiosInstance, AxiosResponse } from "axios";
import httpStatus from "http-status";

import { Event } from "./enum";
import type { ISessionTimeoutEventPayload } from "./interface";

const transparentRequestProxy = async (
  res: AxiosResponse
): Promise<AxiosResponse> => {
  return res;
};

const handleUnauthorisedResponse = (err: any) => {
  const location = err?.response?.headers["location"];

  if (location === undefined) {
    console.error(
      `[${httpStatus.UNAUTHORIZED}] no location found. location=`,
      location
    );
    return;
  }

  const logoutEvent = new CustomEvent<ISessionTimeoutEventPayload>(
    Event.SessionTimeout,
    {
      bubbles: true,
      detail: { location },
    }
  );
  window.dispatchEvent(logoutEvent);
};

// wrapWithEventBasedInterceptor provides an interface to wrap
// an axios client instance with an event based interceptor on requests
export const wrapWithEventBasedInterceptor = (
  axiosClient: AxiosInstance
): AxiosInstance => {
  axiosClient.interceptors.response.use(
    transparentRequestProxy,
    async (err) => {
      if (httpStatus.UNAUTHORIZED === err?.response?.status) {
        handleUnauthorisedResponse(err);
      }

      return Promise.reject(err);
    }
  );

  return axiosClient;
};
