import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { observer } from 'mobx-react';

import {
  CyTheme,
  LoadingOverlay,
  Text,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';

import { useRootStore } from '../../../hooks/useRootStore';
import { AppRoute } from '../../../routes';
import PasswordResetAPI from '../../../services/api/resetPassword';
import { ValidateType } from '../../../services/password-api';
import { useResetScreenReaction } from '../../hooks';
import ResetPasswordWrapper from '../../shared/ResetPasswordWrapper';

import Sections from './Sections';

interface ResetPasswordScreenProps {
  username?: string;
}

const ResetPasswordScreen: FC<
  React.PropsWithChildren<ResetPasswordScreenProps>
> = ({ username = '' }) => {
  const {
    stateStore: { resetPasswordScreenState },
    uiStore,
  } = useRootStore();

  useResetScreenReaction(
    resetPasswordScreenState,
    uiStore,
    ValidateType.ResetPassword,
  );

  if (!username) {
    uiStore.setLoadingState('error');
  }

  const theme = useThemeSafe();
  const styles = useStyles({ theme });

  useAsyncEffect(async () => {
    const p = await PasswordResetAPI.userIdentityProvider(username);
    if (!p.passwordPolicy) {
      return;
    }
    resetPasswordScreenState.setPasswordPolicy(p.passwordPolicy);
  }, []);

  if (resetPasswordScreenState.validator === null) {
    return <LoadingOverlay />;
  }

  return (
    <ResetPasswordWrapper>
      <ResetPasswordWrapper.Title>
        <Text variant="login-sub-title">Reset Password</Text>
        <Text variant="body">Create a strong password</Text>
      </ResetPasswordWrapper.Title>
      <ResetPasswordWrapper.Body>
        <Sections store={resetPasswordScreenState} />
        <div className={styles.cancelWrap}>
          <Link to={AppRoute.Password}>
            <Text variant="body" color={theme.palette.primary.color}>
              Cancel
            </Text>
          </Link>
        </div>
      </ResetPasswordWrapper.Body>
    </ResetPasswordWrapper>
  );
};

const useStyles = createUseStyles((theme: CyTheme) => ({
  cancelWrap: {
    display: 'flex',
    marginTop: 32,
    marginLeft: 'auto',

    '& a': {
      color: theme.palette.primary.color,
      fontFamily: 'sans-serif',
      fontStyle: 'normal',
      textAlign: 'center',
    },
  },
}));

export default observer(ResetPasswordScreen);
