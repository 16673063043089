import { GeneralIdpObject } from 'services';
import { v1 as uuid } from 'uuid';

import { makeAutoObservable } from 'mobx';

import { DtoConvertible } from '@frontend-monorepo/cyolo-store';

import { IdpType } from '../model/types';

import { IBaseIdentityProvider } from './interface';

/**
 * a slim description of an identity provider
 */
export class IdentityProviderDescriptor
  implements DtoConvertible<GeneralIdpObject>, IBaseIdentityProvider
{
  /**
   * returns a new instance of a ipd
   * @param id
   * @param typeUniqueId
   * @param idpType
   * @param idpName
   * @param visible
   */
  constructor(
    public id: string = uuid(),
    public typeUniqueId: string = '', // id is global unique idp id while typeUniqueId is a local table id
    public idpType: IdpType = 'local',
    public idpName: string = '',
    public visible: boolean = true,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  /**
   * parses raw object
   * @param res
   * @returns
   */
  static fromRaw(res: GeneralIdpObject): IdentityProviderDescriptor {
    return new IdentityProviderDescriptor(
      String(res.id),
      String(res.idp_id),
      res.idp_kind as IdpType,
      res.name,
      res.visible,
    );
  }

  /**
   * converts this ipd to a raw object
   * @returns
   */
  toRaw(): GeneralIdpObject {
    const { typeUniqueId, id, idpType, idpName, visible } = this;
    return {
      id: Number(id),
      idp_id: Number(typeUniqueId),
      idp_kind: String(idpType),
      name: idpName,
      visible: visible,
    };
  }

  /**
   * converts a identity provider into a more general ipd
   * @param idp
   * @returns
   */
  static fromIdentityProvider(
    idp: IdentityProvider,
  ): IdentityProviderDescriptor {
    return new IdentityProviderDescriptor(
      idp.id,
      idp.typeUniqueId,
      String(idp.idpType) as IdpType,
      idp.idpName,
      idp.visible,
    );
  }

  /**
   * clones a instance
   * @returns
   */
  clone(): IdentityProviderDescriptor {
    return new IdentityProviderDescriptor(
      this.id,
      this.typeUniqueId,
      this.idpType,
      this.idpName,
      this.visible,
    );
  }

  get formattedIdpType(): string {
    switch (this.idpType) {
      case 'openid':
        return 'OpenID';
      case 'ldap':
        return 'LDAP';
      case 'radius':
        return 'Radius';
      case 'saml':
        return 'SAML';
      case 'local':
        return 'Local';
      default:
        return this.idpType;
    }
  }
}

export default IdentityProviderDescriptor;

interface IdentityProvider {
  id: string;
  typeUniqueId: string;
  idpType: string;
  idpName: string;
  visible: boolean;
}
