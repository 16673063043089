import {
  FormatOptions,
  formatTimestampToDate,
} from '@frontend-monorepo/cyolo-utils';

export const ZeroValueText = 'Never';

export const formatDate = (timestamp: string): string => {
  return formatTimestampToDate(timestamp, {
    ...new FormatOptions(),
    zeroValueText: ZeroValueText,
  });
};
