/**
 * Represents an entity which can be identified using the identifier of type `T`.
 * The default value for `T` is `string`
 *
 * @typeparam T - The type of the identifier
 */
export interface Identifiable<T = string> {
  id: T;
}

export interface UniqueIdentifiable extends Identifiable {
  kind?: string; // adding a kind to avoid on entity mix, same id collision
}

export type UniqueID = string;
/**
 * getSystemUniqueID return a system unique id
 */
export function getSystemUniqueID(target: UniqueIdentifiable): UniqueID {
  if (!target.kind || target.kind === '') {
    console.log(
      '[getSystemUniqueID] found Identifiable entity with missing kind. entity=',
      target,
    );
    return target.id;
  }
  return `${target.kind}_${target.id}`;
}
