import { makeAutoObservable } from 'mobx';

import DataStoreContainer from '../data/data';

type Destination = 'none' | 'login' | 'redirect' | 'welcome' | 'redirect-url';

class RedirectScreenState {
  // selectedIdp is the selected idp by the hash url parameter.
  selectedIdpFromUrl = '';
  redirectUrl = '';
  redirectDestination: Destination = 'none';

  get isInitialLoadingFinished(): boolean {
    const { userIdentityStore, authDataStore, authProvidersStore } =
      this.dataStores;

    return (
      userIdentityStore.state === 'done' &&
      authDataStore.state === 'done' &&
      authProvidersStore.state === 'done'
    );
  }

  bootstrap() {
    const authData = this.dataStores.authDataStore.data;
    const storedUsername = this.dataStores.userIdentityStore.data.username;
    const allProviders = this.dataStores.authProvidersStore.data;

    // user is authenticated
    if (!authData.isAnonymous) {
      this.setRedirectDestination('redirect');
      return;
    }

    // idp is getting from hash in URL, user is anon but has explicit redirect url
    if (this.selectedIdpFromUrl) {
      const foundProvider = allProviders.find((idp) => {
        return idp.name === this.selectedIdpFromUrl;
      });

      if (foundProvider) {
        this.setRedirectUrl(foundProvider.url);

        this.setRedirectDestination('redirect-url');
        return;
      }
    }

    // user is stored in cookies
    if (storedUsername) {
      this.setRedirectDestination('welcome');

      return;
    }

    // user is anon without any redirect url
    this.setRedirectDestination('login');
  }

  private setRedirectDestination(dst: Destination) {
    this.redirectDestination = dst;
  }

  constructor(private readonly dataStores: DataStoreContainer) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setSelectedIdpFromUrl(idpName: string) {
    this.selectedIdpFromUrl = idpName;
  }

  setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }
}

export default RedirectScreenState;
