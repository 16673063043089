export enum StaticEndpoints {
  Login = '/v1/auth/stage/1',
  /* GET */
  GET_QrCodeUri = '/v1/users/me/totp-key-uri',
  GET_UserMfaMethods = '/v1/users/me/mfa/methods',
  GET_AuthProviders = '/v1/auth/providers',
  GET_EnrollmentRequirements = '/v1/auth/enroll/requirements',
  GET_UserProviders = '/v1/auth/user_providers',
  GET_LastLoggedInUser = '/v1/users/me/last-login',

  /* POST */
  POST_MfaLoginConfirmation = '/v1/auth/stage/2',
  POST_InitiateMfaSmsMessage = '/v1/auth/stage/2',
  POST_InitiateMfaEmailMessage = '/v1/auth/stage/2',
  POST_EnrollWithMfaCode = '/v1/users/me/verify',
  PUT_UpdateComputerName = '/v1/users/me',
  POST_SubmitPhoneNumber = '/v1/users/me/initiate-sms',
  POST_SubmitInitiateEmail = '/v1/users/me/initiate-email',
  POST_SaveUser = '/v1/users/me/commit',
  POST_LoginIdentity = 'v1/login/identity',
  POST_ClearUserFromCookies = '/v1/users/welcome/new',
  POST_LegalDocumentationConsent = '/v1/users/me/legal-documentation-consent',
}

export const DynamicEndpoints = {
  GET_POST_Login(providerType: string, providerId: string) {
    return `/v1/auth/${providerType}/${providerId}/stage/1`;
  },
};

export default {
  ...StaticEndpoints,
  ...DynamicEndpoints,
};
