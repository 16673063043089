import React, { FC } from 'react';

import ToastContainer from '../ToastContainer';
import { ToastConsumer } from '../ToastContext';

import styles from './Toaster.styles';

type ToasterPositions =
  | 'top-center'
  | 'top-left'
  | 'top-right'
  | 'bottom-center'
  | 'bottom-left'
  | 'bottom-right';

interface ToasterProps {
  position?: ToasterPositions;
}

const Toaster: FC<ToasterProps> = ({ position = 'bottom-center' }) => (
  <ToastConsumer>
    {({ toasts, deleteToast }): JSX.Element => (
      <div style={styles[position]}>
        {toasts.map((toast) => (
          <ToastContainer key={toast.id} {...toast} deleteToast={deleteToast} />
        ))}
      </div>
    )}
  </ToastConsumer>
);

export default Toaster;
