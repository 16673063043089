// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Heebo-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/Heebo-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/Montserrat-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/Montserrat-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/Montserrat-SemiBold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "@font-face{font-family:\"Heebo\";font-style:normal;font-weight:400;font-display:swap;src:local(\"Heebo-Regular\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"ttf\")}@font-face{font-family:\"Heebo\";font-style:normal;font-weight:500;font-display:swap;src:local(\"Heebo-Medium\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"ttf\")}@font-face{font-family:\"Montserrat\";font-style:normal;font-weight:400;src:local(\"Montserrat Regular\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"ttf\")}@font-face{font-family:\"Montserrat\";font-style:normal;font-weight:500;src:local(\"Montserrat-Medium\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"ttf\")}@font-face{font-family:\"Montserrat\";font-style:normal;font-weight:600;src:local(\"Montserrat-SemiBold\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"ttf\")}", ""]);
// Exports
module.exports = exports;
