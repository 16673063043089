const allButFirstLetterIndex = 1;

export const capitalizeFirstLetterOnly = (
  text: string,
  exceptions?: string[],
): string => {
  if (!Boolean(text)) return text;
  if (exceptions?.includes(text)) return text;
  return text.charAt(0).toUpperCase() + text.slice(allButFirstLetterIndex);
};
