import React, { FC, PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';

import { Text, useThemeSafe } from '@frontend-monorepo/cyolo-ui';

const Title: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const theme = useThemeSafe();

  return (
    <Text variant="body" color={theme.palette.secondary.color}>
      {children}
    </Text>
  );
};

const RequirementsSection: FC<PropsWithChildren<unknown>> & {
  Title: typeof Title;
} = ({ children }) => {
  const styles = useStyles();

  return <div className={styles.root}>{children}</div>;
};

RequirementsSection.Title = Title;

export default RequirementsSection;

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 224,

    '& > div': {
      display: 'flex',
      marginTop: 8,
    },
  },
});
