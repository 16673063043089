import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { observer } from 'mobx-react';

import { useRootStore } from '../../../../hooks/useRootStore';
import AgentAskWebLogout from '../Components/AgentAskWebLogout';
import AgentForceAgentLogout from '../Components/AgentForceAgentLogout';
import AgentForceWebLogout from '../Components/AgentForceWebLogout';
import AgentLoggedOut from '../Components/AgentLoggedOut';

const AgentLogoutScreen: FC = () => {
  const {
    dataStores: {
      authDataStore: {
        data: { authenticated: isWebAuthenticated },
      },
    },
  } = useRootStore();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get('mode');
  const source = queryParams.get('source');

  const syncLogoutWithWeb = mode === 'auto';
  const forceAgentLogout = source === 'web';
  const loggedOutFromBoth = source === 'agent';

  return (() => {
    if (isWebAuthenticated) {
      return syncLogoutWithWeb ? (
        <AgentForceWebLogout />
      ) : (
        <AgentAskWebLogout />
      );
    }

    if (forceAgentLogout) {
      // this means that the user logged out from the web
      // and we force logging out from the agent as well
      return <AgentForceAgentLogout />;
    }

    if (loggedOutFromBoth) {
      // this means that the user logged out from the agent,
      // was redirected to logout and then redirected here again
      // after succesfully logging out from both
      return <AgentLoggedOut loggedFromBoth={true} />;
    }

    return <AgentLoggedOut loggedFromBoth={false} />;
  })();
};

export default observer(AgentLogoutScreen);
