import { makeObservable, observable } from 'mobx';

class AppUrls {
  applicationsPortal: string;

  constructor(applicationsPortal: string) {
    makeObservable(this, {
      applicationsPortal: observable,
    });

    this.applicationsPortal = applicationsPortal;
  }
}

export default AppUrls;
