import { useEffect } from 'react';
import debounce from 'lodash/debounce';

import { reaction } from 'mobx';

import { tryParsingBackendError } from '@frontend-monorepo/http-client';

import { ValidateType } from '../../services/password-api';
import { ResetPasswordScreenBase } from '../../stores/state-store/update-and-reset-password-screen-state';
import UiStore from '../../stores/ui-store';

const useResetScreenReaction = (
  store: ResetPasswordScreenBase,
  uiStore: UiStore,
  type: ValidateType,
) => {
  useEffect(() => {
    // reset policy validations when user types new value
    return reaction(
      () => store.newPasswordState.newPassword,
      () => {
        store.policyValidator?.resetAsyncRules();
      },
    );
  }, []);

  useEffect(() => {
    // validate frontend rules
    return reaction(
      () => store.newPasswordState.newPassword,
      debounce((newPassword) => {
        store.validator?.rules.forEach((rule) => {
          rule.validatePassword(newPassword);
        });
        store.policyValidator?.minLengthRule.validatePassword(newPassword);
        store.policyValidator?.maxLengthRule.validatePassword(newPassword);
      }, 500),
    );
  }, []);

  useEffect(() => {
    // fetch async validations when front end validations are met
    return reaction(
      (): [boolean, string] => [
        store.isComplexityValid,
        store.newPasswordState.newPassword,
      ],
      debounce(([complexityValid, newPassword]) => {
        if (complexityValid && newPassword.length) {
          store.policyValidator?.validateAsync(newPassword, type, (err) => {
            uiStore.showToast(
              tryParsingBackendError(err, 'Failed to validate password'),
              'refused',
            );
          });
        }
      }, 1200),
    );
  }, []);
};

export default useResetScreenReaction;
