import { makeAutoObservable } from 'mobx';

import { DtoConvertible } from '@frontend-monorepo/cyolo-store';
import { Identifiable } from '@frontend-monorepo/cyolo-utils';

import {
  ICompanyInfoObject,
  ICompanyInfoUpdatableObject,
} from '../services/api/company';
import { ResourceUrl } from '../types';

class CompanyInfo
  implements DtoConvertible<ICompanyInfoUpdatableObject>, Identifiable
{
  constructor(
    public id: string = '',
    public name: string = '',
    public welcomeMessage: string = '',
    public icon: string = '',
    public personalDesktopMessage: string = '',
    public serversWatermark: ResourceUrl = '',
    public adminConsoleLogo: ResourceUrl = '',
    public headerPlaceHolder: string = '',
    public legalDocTitle: string = '',
    public legalDocBody: string = '',
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  /**
   * returns if an icon exits
   */
  get iconExists(): boolean {
    if (this.icon === '' || this.icon === undefined) {
      return false;
    }
    return true;
  }

  /**
   * returns if servers watermark exists
   */
  get serversWatermarkExists(): boolean {
    if (this.serversWatermark === '' || this.serversWatermark === undefined) {
      return false;
    }
    return true;
  }

  /**
   * returns if admin console logo exists.
   */
  get adminConsoleLogoExists(): boolean {
    return Boolean(this.adminConsoleLogo);
  }

  static fromRaw(obj: ICompanyInfoObject): CompanyInfo {
    const {
      id,
      name,
      welcome_message,
      icon,
      personal_desktop_message,
      servers_watermark,
      admin_console_logo,
      header_placeholder,
      legal_doc_title,
      legal_doc_body,
    } = obj;
    return new CompanyInfo(
      id,
      name,
      welcome_message,
      icon,
      personal_desktop_message,
      servers_watermark,
      admin_console_logo,
      header_placeholder,
      legal_doc_title,
      legal_doc_body,
    );
  }

  toRaw(): ICompanyInfoUpdatableObject {
    const {
      welcomeMessage,
      personalDesktopMessage,
      headerPlaceHolder,
      legalDocTitle,
      legalDocBody,
    } = this;
    return {
      welcome_message: welcomeMessage,
      personal_desktop_message: personalDesktopMessage,
      header_placeholder: headerPlaceHolder,
      legal_doc_title: legalDocTitle,
      legal_doc_body: legalDocBody,
    };
  }
}

export default CompanyInfo;
