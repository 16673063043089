import { DtoConvertible } from '@frontend-monorepo/cyolo-store';
import axios from '@frontend-monorepo/http-client';

import Endpoints from './endpoints';
import { ICompanyInfoObject, ICompanyInfoUpdatableObject } from './response';

const getCompanyInfo = async (): Promise<ICompanyInfoObject> => {
  const { data } = await axios.get<ICompanyInfoObject>(
    Endpoints.GetCompanyInfo,
  );
  return data;
};

const updateCompanyInfo = async (
  companyInfo: DtoConvertible<ICompanyInfoUpdatableObject>,
): Promise<void> => {
  await axios.post(Endpoints.UpdateCompanyInfo, companyInfo.toRaw());
};

export default {
  getCompanyInfo,
  updateCompanyInfo,
};
