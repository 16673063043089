import { action, autorun, makeObservable, observable } from 'mobx';

import { EnrollmentPhase, SkippedPhases } from '../model';

class EnrollmentPhaseSkipStore {
  /**
   * EnrollmentPhaseSkipStore holds data that relates the skipped phases in the
   * end user enrollment phase which are saved to session storage
   */

  // skippedPhases is the array that holds the end user skipped enrollment phases
  // in memory
  skippedPhases: Array<EnrollmentPhase> = [];

  constructor() {
    makeObservable(this, {
      skippedPhases: observable,
      addSkippedPhase: action,
    });

    // get initial skipped phases from session storage
    this.skippedPhases = SkippedPhases.get();

    // write the skipped phases to session storage on change
    autorun(() => {
      SkippedPhases.set(this.skippedPhases);
    });
  }

  addSkippedPhase(phase: EnrollmentPhase): void {
    /**
     * addSkippedPhase pushes a new skipped phase the the skippedPhases array
     */
    this.skippedPhases.push(phase);
  }

  canSkip(phase: EnrollmentPhase): boolean {
    return this.skippedPhases.includes(phase);
  }
}

export default EnrollmentPhaseSkipStore;
