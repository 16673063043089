import React, { FC, useLayoutEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

import {
  Button,
  CyTheme,
  Icon,
  StatusIcon,
  Text,
  useThemeSafe,
} from '@frontend-monorepo/cyolo-ui';

import { DefaultTimeout } from '../../const';
import Toast from '../../models/toast';

interface ToastContainerProps extends Toast {
  deleteToast: (id: string) => void;
}

const ToastContainer: FC<ToastContainerProps> = ({
  deleteToast,
  message,
  id,
  onSubmit,
  submitText,
  type = 'approved',
  timeout = DefaultTimeout,
}) => {
  const dismissToast = (): void => {
    setVisible(false);
    deleteToast(id);
  };

  const theme = useThemeSafe();
  const styles = useStyles({ theme });
  const [visible, setVisible] = useState(false);

  // set a timeout only if there's no on submit func
  const delayPopMS = 50;
  useLayoutEffect(() => {
    if (message) {
      const timerDelay = setTimeout(() => {
        if (onSubmit) {
          // dont autohide
          setVisible(true);
          return;
        }

        setVisible(true);
      }, delayPopMS);

      const timer = setTimeout(() => {
        setVisible(false);
      }, timeout);

      return () => {
        timer && clearTimeout(timer);
        timerDelay && clearTimeout(timerDelay);
      };
    }
  }, [onSubmit]);

  return (
    <div className={clsx(styles.toastStyle, visible ? styles.show : '')}>
      {!onSubmit && (
        <div className={styles.statusIcon}>
          {type === 'approved' ? (
            <StatusIcon type="approved" />
          ) : (
            <StatusIcon type="refused" />
          )}
        </div>
      )}
      <Text className={styles.text} variant="toast-message">
        {message}
      </Text>
      {submitText ? (
        <Button
          className={styles.toastActionButton}
          variant="primary"
          onClick={() => {
            onSubmit();
            dismissToast();
          }}
        >
          {submitText}
        </Button>
      ) : (
        <button
          className={styles.toastButton}
          onClick={dismissToast}
          type="button"
        >
          <Icon id="x-icon-white" size={8} />
        </button>
      )}
    </div>
  );
};

const useStyles = createUseStyles((theme: CyTheme) => ({
  toastStyle: {
    backgroundColor: theme.palette.primary.extraDark,
    display: 'flex',
    width: 600,
    height: 40,
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    alignItems: 'center',
    marginBottom: '10px',
    zIndex: theme.zIndex.max,
    opacity: 0,
    transform: 'translateY(250px)',
    transition: `all ${theme.transitions.medium}`,
  },
  show: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  toastButton: {
    position: 'absolute',
    right: 0,
    marginRight: '8px',
    height: '24px',
    backgroundColor: 'initial',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },

  statusIcon: {
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center',
  },

  text: {
    marginLeft: '18px',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
    maxWidth: 520,
  },

  toastActionButton: {
    position: 'absolute',
    right: 0,
    marginRight: '16px',
    cursor: 'pointer',
  },
}));

export default ToastContainer;
