import DataStoreContainer from '../../data/data';
import UiStore from '../../ui-store';

import LegalViewState from './legal-view-state';
import MfaViewState from './mfa-view-state';
import PersonalDesktopViewState from './personal-desktop-view-state';

class EnrollScreenState {
  personalDesktopViewState: PersonalDesktopViewState;

  mfaViewState: MfaViewState;

  legalViewState: LegalViewState;

  constructor(dataStores: DataStoreContainer, uiStore: UiStore) {
    this.personalDesktopViewState = new PersonalDesktopViewState(dataStores);
    this.mfaViewState = new MfaViewState(dataStores, uiStore);
    this.legalViewState = new LegalViewState(dataStores);
  }
}

export default EnrollScreenState;
