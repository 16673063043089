export enum HttpResponse {
  HttpNotFound = 404,
}

/**
 * doesImageSrcExist tries to access an img src and return true if it was found.
 * @param url is the path to the img
 */
const doesImageSrcExist = (url: string): boolean => {
  const http = new XMLHttpRequest();

  http.open('GET', url, false);
  http.send();

  return http.status !== HttpResponse.HttpNotFound;
};

export { doesImageSrcExist };
