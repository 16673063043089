export enum Stage {
  Dev = 'dev',
  Staging = 'staging',
  Prod = 'production',
}

const LocalRegex = /(127.0.0.1|0.0.0.0|localhost)/;
const DevRegex = /\.(dev|local|qa)\./;
const StagingRegex = /\.(staging|stg)\./;

// inferAppStage tries to determine the current
// app release stage from the url
export const inferAppStage = (hostname = window.location.hostname): Stage => {
  if (LocalRegex.test(hostname) || DevRegex.test(hostname)) {
    return Stage.Dev;
  }

  if (StagingRegex.test(hostname)) {
    return Stage.Staging;
  }

  return Stage.Prod;
};

export default {
  inferAppStage,
};
