const Endpoints = {
  GetUsers: '/v1/users',
  GetUserData: '/v1/users/me',
  CreateUser: '/v1/users',
  DeleteUsersBulk: '/v1/users/bulk',
  CreatUserBulk: '/v1/users/bulk',
  PATCH_UpdatePassword: '/v1/users/me/password/change',
  UpdateUser(id: string) {
    return `/v1/users/${id}`;
  },
  DeleteUser(id: string) {
    return `/v1/users/${id}`;
  },
  ResetEnrollmentBulk: '/v1/users/reset-enrollment',
};

export default Endpoints;
