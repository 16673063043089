import { useEffect, useState } from 'react';

export const useKeyboardPress = (targetCode: number, handler: () => void) => {
  const [inTimeout, setInTimeout] = useState(false);
  const LONG_PRESS_INTERVAL_MS = 500;

  /**
   * on keydown press
   */
  function handlerDownKey(e: KeyboardEvent) {
    if (e.keyCode === targetCode) {
      // support long press
      if (!inTimeout) {
        setInTimeout(true);
        setTimeout(() => {
          setInTimeout(false);
        }, LONG_PRESS_INTERVAL_MS);

        handler();
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handlerDownKey);

    return () => {
      window.removeEventListener('keydown', handlerDownKey);
    };
  }, []);
};
