export enum SortOrder {
  ASC,
  DESC,
}

const isString = (candidate: unknown): candidate is string =>
  typeof candidate === 'string' || candidate instanceof String;

export const sortListOfObjects = <T>(
  list: Array<T>,
  sortBy: keyof T,
  order: SortOrder,
) => {
  return list.sort((a, b) => {
    let firstValue = a[sortBy];
    let secondValue = b[sortBy];

    if (order === SortOrder.DESC) {
      firstValue = b[sortBy];
      secondValue = a[sortBy];
    }

    if (isString(firstValue) && isString(secondValue)) {
      return firstValue.localeCompare(secondValue, undefined, {
        sensitivity: 'base',
      });
    }

    if (firstValue < secondValue) {
      return -1;
    }

    if (firstValue > secondValue) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      return 1;
    }

    return 0;
  });
};
