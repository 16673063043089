import Fuse from 'fuse.js';

export const searchBy = <T>(
  searchValue: string,
  array: T[],
  ...keys: (keyof T | T[keyof T])[]
): T[] => {
  const options = {
    includeScore: false,
    keys: keys as string[],
    threshold: 0,
    shouldSort: false,
    ignoreLocation: true,
  };

  const fuse = new Fuse(array, options);
  const result = fuse.search(searchValue);

  return result.map((res) => res.item);
};
