import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useRootStore } from '../../../hooks';

// HistoryMonitor is a empty component in charge of syncing react router to
// the MobX store containing the location of the current page
const HistoryMonitor: FC = () => {
  const location = useLocation();
  const { locationStore } = useRootStore();

  useEffect(() => {
    locationStore.updateLocation(location.pathname);
  }, [location.pathname]);
  return null;
};

export default HistoryMonitor;
