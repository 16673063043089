import React, { FC, PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';

import { observer } from 'mobx-react';

import { CyTheme, useThemeSafe } from '@frontend-monorepo/cyolo-ui';

import { useRootStore } from '../../../../hooks/useRootStore';

import Circle from './Circle';

const useStyles = createUseStyles((theme: CyTheme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    gap: 12,
    position: 'relative',
  },
  line: {
    position: 'absolute',
    top: 22,
    width: '85%',
    backgroundColor: theme.palette.primary.extraLight,
    height: 1,
    zIndex: 0,
  },
}));

const Stepper: FC<PropsWithChildren> = ({}) => {
  const {
    stateStore: { requestPasswordResetState: store },
  } = useRootStore();
  const theme = useThemeSafe();
  const styles = useStyles({ theme });

  const children: React.ReactNode[] = [];
  {
    store.steps.forEach((step) => {
      children.push(
        <Circle
          key={step}
          number={step}
          step={store.stepState(step)}
          description={store.description(step)}
        />,
      );
    });
  }

  return (
    <div className={styles.root}>
      <div className={styles.line} />
      {children}
    </div>
  );
};

export default observer(Stepper);
