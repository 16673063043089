export enum OperatingSystem {
  Windows = 'windows',
  Linux = 'linux',
  Darwin = 'darwin',
  Mac = 'mac',
  Etc = 'etc',
}

/**
 * getOperatingSystem
 * @param withMobiles set "true" if you need to be detected on mobiles
 * @returns OS in lower case
 */
export const getOperatingSystem = (withMobiles = false): OperatingSystem => {
  const { platform } = window.navigator;

  if (platform.includes('Linux')) {
    return OperatingSystem.Linux;
  }

  if (platform.includes('Win')) {
    return OperatingSystem.Windows;
  }

  if (platform.includes('Mac')) {
    return OperatingSystem.Darwin;
  }

  if (withMobiles) {
    if (platform.includes('Android')) {
      return OperatingSystem.Linux;
    }

    if (platform.includes('iPhone')) {
      return OperatingSystem.Mac;
    }

    if (platform.includes('iPad')) {
      return OperatingSystem.Mac;
    }
  }

  return OperatingSystem.Etc;
};
