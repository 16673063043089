import React, { FC, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import { observer } from 'mobx-react';

import { LoadingIndicator } from '@frontend-monorepo/cyolo-ui';

import Endpoints from '../../../../services/api/endpoints';
import pageRedirect from '../../../../services/page-redirect';

const AgentForceWebLogout: FC = () => {
  const styles = useStyles();

  useEffect(() => {
    pageRedirect.performPageRedirect(`${Endpoints.logout}?source=agent`);
  }, []);

  return (
    <div className={styles.spinnerContainer}>
      <LoadingIndicator color="blue" />
    </div>
  );
};

export default observer(AgentForceWebLogout);

const useStyles = createUseStyles({
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
