interface InformativeBackendError {
  response: {
    data: {
      error: string;
    };
  };
}

const isInformativeBackendError = (
  obj: unknown
): obj is InformativeBackendError => {
  return (obj as InformativeBackendError)?.response?.data?.error !== undefined;
};

/**
 * try to read and return an error from the response.
 * @param e
 * @param defaultError
 */
export const tryParsingBackendError = (
  e: unknown,
  defaultError: string
): string => {
  return isInformativeBackendError(e) ? e.response.data.error : defaultError;
};
